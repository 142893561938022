<template>
    <div
        class="relative"
        style="height: 100vh; overflow-y: hidden"
        v-if="contract"
    >
        <div
            class="w-full dF jSB aC"
            style="background: white; padding: 1rem 1.33rem; height: 60px"
        >
            <div
                @click="goBack"
                class="backButton dF aC p-2"
                style="cursor: pointer"
            >
                <a-icon type="left" class="mr-1" />
                BACK
            </div>
            <div class="dF jC aC" v-if="currentPageObj">
                <Icons
                    type="contract-file"
                    class="mr-2"
                    style="color: var(--red); width: 16px; height: 16px"
                />
                <span>{{ contract.name }}</span>
            </div>
            <PageButtons />
        </div>
        <InsertModal
            @refresh="setupView"
            @close="insertPagesModal.show = false"
            :data="insertPagesModal"
            :contract="contract"
        />

        <ShuffleModal
            @updatePages="updatePages"
            @close="shufflePageModal.show = false"
            :data="shufflePageModal"
            :contract="contract"
        />

        <main class="dF relative" style="height: calc(100% - 60px); flex: auto">
            <div
                style="width: 100%"
                :style="`--canvasWidth:${canvasWidth}px; --canvasHeight:${canvasHeight}px`"
            >
                <a-modal
                    title="Custom Field"
                    :visible="editField.show"
                    @ok="makeCustomField"
                    @cancel="editField.show = false"
                >
                    <a-form-model
                        ref="newCustomField"
                        :model="newCustomField"
                        :rules="rules"
                    >
                        <a-form-model-item
                            label="Field Label"
                            prop="name"
                            class="mb-2"
                        >
                            <a-input
                                v-model="newCustomField.name"
                                autocomplete="off"
                            />
                        </a-form-model-item>
                        <a-form-model-item prop="help" class="mb-2">
                            <span slot="label">
                                Field Help
                                <a-tooltip
                                    title="Use this field if a footnote is required under field in Transaction Manager"
									overlayClassName="change-tooltip-color"
                                >
                                    <a-icon
                                        class="text-info ml-2"
                                        type="question-circle"
                                    />
                                </a-tooltip>
                            </span>
                            <a-input
                                v-model="newCustomField.help"
                                autocomplete="off"
                            />
                        </a-form-model-item>

                        <a-form-model-item
                            label="Field Type"
                            prop="type"
                            class="mb-0"
                        >
                            <a-select
                                :disabled="Boolean(editField.id)"
                                v-model="newCustomField.type"
                                placeholder="Select a field type"
                            >
                                <a-select-option v-for="field in customFieldList" :key="field.value" :value="field.value">
									{{ field.label }}
								</a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <template v-if="newCustomField?.type === 'multiple'">
                            <a-row :gutter="16" class="mt-3">
                                <a-col :span="18">
                                    <a-form-model-item
                                        prop=""
                                        label="Options"
                                        class="mb-0"
                                    ></a-form-model-item>
                                </a-col>
                                <a-col :span="6" class="dF jSB">
                                    <a-form-model-item
                                        prop=""
                                        label="Default"
                                        class="mb-0"
                                    ></a-form-model-item>
                                </a-col>
                            </a-row>
                            <a-row
                                :gutter="16"
                                v-for="(item, index) in newCustomField.configure.options"
                                :key="index + 'options'"
                            >
                                <a-col :span="18">
                                    <a-form-model-item
                                        label=""
                                        class="mb-1"
                                        :prop="
                                            'configure.options.' +
                                            index +
                                            '.value'
                                        "
                                        :rules="{
                                            required: true,
                                            message: 'Value can not be empty',
                                            trigger: 'blur',
                                        }"
                                    >
                                        <a-input
                                            v-model="item.value"
                                            placeholder="Please input a value"
                                            style="margin-right: 8px"
                                        />
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="6" class="dF jSB">
                                    <a-form-model-item
                                        label=""
                                        prop=""
                                        class="mb-1"
                                    >
										<a-radio-group :value="newCustomField.configure.defaultValue" @change="updateDefaultCustomOption">
									      <a-radio :value="index.toString()"></a-radio>
									    </a-radio-group>
                                    </a-form-model-item>
                                    <a-form-model-item
                                        label=""
                                        prop=""
                                        class="mb-1"
                                    >
                                        <i
                                            v-if="newCustomField.configure.options.length > 2"
                                            @click="onOptionDelete(newCustomField, index)"
                                            class="
                                                fe fe-trash
                                                cursor-pointer
                                                hover:text-primary
                                            "
                                        ></i>
                                    </a-form-model-item>
                                </a-col>
                            </a-row>

                            <a-form-model-item prop="" label="">
                                <a
                                    href="#"
                                    @click.prevent="addNewCustomOption"
                                    class="
                                        hover:text-primary
                                        cursor-pointer
                                        no-selection
                                    "
                                >
                                    <i class="fe fe-plus-circle" /> Add new
                                </a>
                            </a-form-model-item>
                        </template>
                        <template v-if="newCustomField?.type === 'date'" ></template>
                        <template v-else-if="newCustomField?.type === 'image'" >
                            <a-form-model-item
                                label="Default Image"
                                class="mb-0 mt-3"
                                prop="configure.defaultValue"
                            >
                                <ImageBoxSelector
                                    v-model="
                                        newCustomField.configure.defaultValue
                                    "
                                />
                            </a-form-model-item>
                        </template>
                        <template v-else-if="newCustomField?.type === 'checkmark'" >
                            <a-form-model-item
                                label="Checked by default ?"
                                class="mb-0 mt-3"
                                prop="configure.defaultValue"
                            >
                                <a-switch
                                    v-model="
                                        newCustomField.configure.defaultValue
                                    "
                                />
                            </a-form-model-item>
                        </template>
                        <template v-else-if="newCustomField?.type === 'text'">
                            <a-form-model-item
                                label="Default Value"
                                class="mb-0 mt-3"
                                prop="configure.defaultValue"
                            >
                                <a-textarea
                                    v-model="
                                        newCustomField.configure.defaultValue
                                    "
                                    autocomplete="off"
                                    :auto-size="{ minRows: 4, maxRows: 12 }"
                                />
                            </a-form-model-item>
                        </template>
						<template v-else-if="newCustomField?.type === 'variable'">
							<!-- create a logic to add text sentence with variable placeholder -->
							 <a-alert type="info" show-icon class="mt-3">
								<template #message>
									Write a sentence with variables in curly braces. For example,
									<br>
									<strong>"The Purchaser shall receive $ {Dollar Number} ({Dollar Words} Dollars), inclusive of HST and any applicable taxes, to spend at the Design Studio towards the purchase of upgrades.  This amount is non-negotiable, non-transferrable and has no cash value."</strong>.
									<br>
									<br>
									This will allow you to replace the variables with actual values when generating the document in transaction.
								</template>
							 </a-alert>
							<a-form-model-item
								label="Text Input"
								class="mb-0 mt-3 variable-text-input"
								prop="configure.defaultValue"
							>
								<a-textarea
									v-model="newCustomField.configure.defaultValue"
									autocomplete="off"
									:auto-size="{ minRows: 4, maxRows: 12 }"
								/>
							</a-form-model-item>

							<!-- create output preview here -->
							<div class="mt-3">
								<h6 class="mb-1">Output Preview</h6>
								<!-- <p class="text-muted">
									{{ newCustomField.configure.defaultValue.replace(/\{.*?\}/g, '  ________  ') }}
								</p> -->
								<h6>
									<span v-for="(part, index) in parsedVariableInput" :key="index">
										<template v-if="part.type === 'text'">{{ part.value }}</template>
										<template v-else>
											<a-input
												v-model="newCustomField.configure.options[part.index].value"
												:style="`min-width: 100px; width: ${newCustomField.configure.options[part.index].value.length * 6}px; border: none; border-bottom: 1px solid gray; padding: 5px`"
											/>
										</template>
									</span>
								</h6>

							</div>

							<!-- what to do if i want to replace curly braces variables with input field like this
								Hello, my name is {name} <a-input v-model="newCustomField.configure.variable[0]" />  and I live in <a-input v-model="newCustomField.configure.variable[1]" />
							-->
						</template>
                        <p v-if="dupFieldName" class="text-danger">
                            Another field exists already with the same name.
                            Please try again.
                        </p>
                    </a-form-model>
                </a-modal>

                <div class="contacts-page h-full">
                    <div
                        class="w-full h-full dF"
                        :class="rightToolbarOpen ? 'right-tool-open' : ''"
                    >
                        <div
                            class="
                                h-full
                                bg-offwhite-dark
                                pdf-canvas-wrapper
                                dF
                                fC
                            "
                        >
                            <div
                                class="dF jC px-5 bg-white pdf-toolbar"
                                style="
                                    border-bottom: 1px solid var(--light-purple);
                                    border-top: 1px solid var(--light-purple);
                                "
                                v-if="pages && pages.length && !draggingField"
                            >
                                <a-tooltip
                                    :mouseEnterDelay="0.4"
                                    @visibleChange="onToolVisible"
                                    :title="item.tooltip"
                                    v-for="item in toolbarItems"
                                    :key="item.value"
									overlayClassName="change-tooltip-color"
                                >
                                    <div
                                        class="py-2 dF aC"
                                        :class="{
                                            'tool-bL': item.bl,
                                            active: selectedTool === item.value,
                                        }"
                                        style="cursor: pointer"
                                        @click="item.fn && item.fn(item.value)"
                                        :key="item.value"
                                    >
                                        <a-icon
                                            :type="item.value"
                                            v-if="item.ant"
                                        />
                                        <Icons :type="item.value" v-else />
                                    </div>
                                </a-tooltip>

                                <div
                                    class="dF aC tool-bL"
                                    v-if="pages && pages.length"
                                >
                                    <a-pagination
                                        simple
                                        v-model="currentPageReadable"
                                        :total="pages.length"
                                        :pageSize="1"
                                    />
                                </div>
                            </div>
							<div
                                class="dF jC bg-white pdf-toolbar"
                                style="
                                    border-bottom: 1px solid var(--light-purple);
                                    border-top: 1px solid var(--light-purple);
                                "
								v-else-if="draggingField"
                            >
								<div>
									<a-alert message="Please place all fields within the contract page, or the contract will not be delivered to recipients." type="error" show-icon />
								</div>
							</div>
                            <div class="hidden dragging-field-preview">
                                <p class="py-1 px-2 drag-preview">PREVIEW</p>
                            </div>
                            <div
                                id="wcp-editor"
                                v-on:drop="(e) => e.preventDefault()"
                                v-on:dragover="(e) => e.preventDefault()"
                                class="f1"
                            ></div>
                        </div>
                        <div
                            id="fieldOptions"
                            style="overflow: hidden"
                            class="h-full bg-offwhite-light dF"
                        >
                            <div class="dF fC right-tool-bar">
                                <div
                                    class="f1 p-4 dF fC relative"
                                    style="
                                        overflow: hidden;
                                        border-top: 1px solid
                                            var(--light-purple);
                                    "
                                >
                                    <div class="dF jSB mb-3">
                                        <h5>
                                            {{
                                                currentRightToolBar[
                                                    rightBarSelected
                                                ]
                                            }}
                                        </h5>
                                        <i
                                            class="
                                                fa fa-times
                                                hover:text-primary
                                            "
                                            style="cursor: pointer"
                                            @click="rightBarSelected = ''"
                                        ></i>
                                    </div>

                                    <a-form
                                        v-if="rightBarSelected === 'fields'"
                                    >
                                        <a-form-item class="mb-0">
                                            <a-select v-model="topFieldsGroup">
                                                <a-select-option
                                                    v-for="(
                                                        group, groupI
                                                    ) of fieldsGrouped"
                                                    :key="groupI"
                                                    :value="groupI"
                                                >
                                                    <span
                                                        style="
                                                            width: 10px;
                                                            height: 10px;
                                                            display: inline-block;
                                                            margin-right: 5px;
                                                            border-radius: 50%;
                                                        "
                                                        :style="{
                                                            backgroundColor:
                                                                group.color,
                                                        }"
                                                    ></span
                                                    >{{ group.name }}
                                                </a-select-option>
                                            </a-select>
                                        </a-form-item>
                                        <a-form-item
                                            v-if="
                                                topFieldsGroup ===
                                                    'Purchaser(s)' ||
                                                topFieldsGroup === 'Tenant(s)'
                                            "
                                        >
                                            <a-select v-model="purchaserNo">
                                                <a-select-option
                                                    v-for="(e, i) of new Array(
                                                        5
                                                    )"
                                                    :key="`field grouped ${i}`"
                                                    :value="i + 1"
                                                >
                                                    {{
                                                        topFieldsGroup.split(
                                                            "("
                                                        )[0]
                                                    }}
                                                    {{ i + 1 }}
                                                </a-select-option>
                                            </a-select>
                                        </a-form-item>
										<a-form-item v-if="topFieldsGroup === 'Dynamic Purchaser(s)'" >
                                            <a-select v-model="purchaserXNo">
                                                <a-select-option
                                                    v-for="(e, i) of new Array( 5 )"
                                                    :key="`field grouped ${i}`"
                                                    :value="i + 1"
                                                >
                                                    {{ topFieldsGroup.split( "(" )[0] }} {{ i + 1 }}
                                                </a-select-option>
                                            </a-select>
                                        </a-form-item>
                                        <a-form-item
                                            v-if="
                                                topFieldsGroup.includes(
                                                    'Signing Off'
                                                )
                                            "
                                        >
                                            <a-select v-model="signingPartyNo">
                                                <a-select-option
                                                    v-for="(e, i) of new Array(
                                                        20
                                                    )"
                                                    :key="`field grouped ${i}`"
                                                    :value="i + 1"
                                                >
                                                    <span>
                                                        Signing Officer
                                                        {{ i + 1 }}</span
                                                    >
                                                </a-select-option>
                                            </a-select>
                                        </a-form-item>
										<a-form-item v-if="topFieldsGroup.includes(`Seller's Agents`)">
                                            <a-select v-model="signingPartyNo">
                                                <a-select-option
                                                    v-for="(e, i) of new Array(5)"
                                                    :key="`field grouped ${i}`"
                                                    :value="i + 1"
                                                >
                                                    <span>Seller's Agents {{ i + 1 }}</span>
                                                </a-select-option>
                                            </a-select>
                                        </a-form-item>
                                        <a-form-item
                                            v-if="
                                                topFieldsGroup.includes(
                                                    'Deposit'
                                                )
                                            "
                                        >
                                            <a-select v-model="depositNo">
                                                <a-select-option
                                                    :value="i + 1"
                                                    v-for="(e, i) in new Array(
                                                        20
                                                    )"
                                                    :key="`deposit ${i}`"
                                                    >Deposit
                                                    {{ i + 1 }}</a-select-option
                                                >
                                            </a-select>
                                        </a-form-item>
										<a-form-item
                                            v-if="
                                                topFieldsGroup.includes(
                                                    'Product Custom Fields'
                                                )
                                            "
                                        >
                                            <a-select v-model="productType">
												<template v-if="instance.productType == 'lowrise'">
													<a-select-option value="lot">
														Lot Custom Fields
													</a-select-option>
													<a-select-option value="models">
														Model Custom Fields
													</a-select-option>
												</template>
												<template v-else>
													<a-select-option value="units">
														Unit Custom Fields
													</a-select-option>
												</template>
                                            </a-select>
                                        </a-form-item>
                                    </a-form>
                                    <div
                                        class="f1 hide-scrollba"
                                        style="
                                            overflow-x: hidden;
                                            overflow-y: auto;
                                        "
                                    >
                                        <div>
                                            <template
                                                v-if="
                                                    rightBarSelected === 'pages'
                                                "
                                            >
                                                <draggable
                                                    :list="pages"
                                                    @start="tagDraggingPage"
                                                    @end="updatePagesOrder"
                                                    class="f1"
													:delay-on-touch-only="true"
													:delay="100"
                                                >
                                                    <div
                                                        v-for="(
                                                            page, pageI
                                                        ) in pages.filter(
                                                            (x) => !x.deleted
                                                        )"
                                                        :key="pageI + 'page'"
                                                        class="page-block px-4"
                                                        style="display: block"
                                                        :data-page-index="
                                                            pageI + 1
                                                        "
                                                        :class="
                                                            currentPage ===
                                                            pageI
                                                                ? 'page-selected'
                                                                : ''
                                                        "
                                                    >
                                                        <div
                                                            class="
                                                                page-img-holder
                                                            "
                                                            @click="
                                                                currentPageReadable =
                                                                    pageI + 1
                                                            "
                                                        >
                                                            <img
                                                                :src="
                                                                    page.image
                                                                "
                                                                class="
                                                                    cursor-pointer
                                                                    page-img
                                                                "
                                                            />
                                                        </div>
                                                        <ul v-if="page">
                                                            <li
                                                                v-if="
                                                                    pages.length >
                                                                    1
                                                                "
                                                            >
                                                                <a-popconfirm
                                                                    title="Are you sure you want to delete this page?"
                                                                    ok-text="Yes"
                                                                    ok-type="danger"
                                                                    cancel-text="No"
                                                                    @confirm="
                                                                        confirmDeletePage
                                                                    "
                                                                    :overlayStyle="{
                                                                        zIndex: 100000,
                                                                    }"
                                                                >
                                                                    <a-tooltip
                                                                        :destroyTooltipOnHide="
                                                                            true
                                                                        "
                                                                        title="Delete page"
                                                                        overlayClassName="change-tooltip-color"
                                                                    >
                                                                        <span
                                                                            @click="
                                                                                deletePage =
                                                                                    page.id
                                                                            "
                                                                        >
                                                                            <a-icon
                                                                                type="delete"
                                                                                class="
                                                                                    pageDelete
                                                                                    text-danger
                                                                                "
                                                                                style="
                                                                                    cursor: pointer;
                                                                                "
                                                                            />
                                                                        </span>
                                                                    </a-tooltip>
                                                                </a-popconfirm>
                                                            </li>
                                                            <li>
                                                                <a-tooltip
                                                                    :destroyTooltipOnHide="
                                                                        true
                                                                    "
                                                                    title="Insert/Replace page"
                                                                    overlayClassName="change-tooltip-color"
                                                                >
                                                                    <span
                                                                        @click="
                                                                            saveFirst(
                                                                                'insert',
                                                                                pageI
                                                                            )
                                                                        "
                                                                    >
                                                                        <a-icon
                                                                            type="file-add"
                                                                            class="
                                                                                pageInsert
                                                                            "
                                                                            style="
                                                                                cursor: pointer;
                                                                            "
                                                                        />
                                                                    </span>
                                                                </a-tooltip>
                                                            </li>
                                                            <li>
                                                                <a-tooltip
                                                                    :destroyTooltipOnHide="
                                                                        true
                                                                    "
                                                                    title="Shuffle/Move page"
                                                                    overlayClassName="change-tooltip-color"
                                                                >
                                                                    <span
                                                                        @click="
                                                                            saveFirst(
                                                                                'shuffle',
                                                                                pageI
                                                                            )
                                                                        "
                                                                    >
                                                                        <a-icon
                                                                            type="drag"
                                                                            class="
                                                                                pageInsert
                                                                            "
                                                                            style="
                                                                                cursor: pointer;
                                                                            "
                                                                        />
                                                                    </span>
                                                                </a-tooltip>
                                                            </li>
                                                            <li>
                                                                <a-tooltip
                                                                    :destroyTooltipOnHide="
                                                                        true
                                                                    "
                                                                    title="Copy page"
                                                                    overlayClassName="change-tooltip-color"
                                                                >
                                                                    <span
                                                                        @click="
                                                                            saveFirst(
                                                                                'duplicate',
                                                                                pageI
                                                                            )
                                                                        "
                                                                    >
                                                                        <a-icon
                                                                            type="copy"
                                                                            class="
                                                                                pageInsert
                                                                            "
                                                                            style="
                                                                                cursor: pointer;
                                                                            "
                                                                        />
                                                                    </span>
                                                                </a-tooltip>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </draggable>
                                            </template>

                                            <template
                                                v-else-if="
                                                    rightBarSelected ===
                                                    'fields'
                                                "
                                            >
                                                <div>
                                                    <div
                                                        :data-field-value="
                                                            field.value
                                                        "
                                                        v-for="field in selectedFields"
                                                        :key="field.value"
                                                        class="
                                                            my-1
                                                            cursor-pointer
                                                            each-field
                                                            py-2
                                                            pl-2
                                                        "
                                                        style="
                                                            overflow: hidden;
                                                            text-overflow: ellipsis;
                                                            white-space: nowrap;
                                                        "
                                                        :title="field.name"
                                                        :class="
                                                            draggingField ===
                                                            field.value
                                                                ? 'field-drag'
                                                                : ''
                                                        "
                                                        :style="{
                                                            color: field.color,
                                                        }"
                                                    >
                                                        <span
                                                            style="
                                                                font-size: 11px;
                                                                font-weight: bold;
                                                                padding: 0 1px;
                                                            "
                                                            :style="`border:1px solid ${field.color}; border-radius:5px;`"
                                                            v-if="field.number"
                                                            >12</span
                                                        >
                                                        <i
                                                            class="fa fa-dollar"
                                                            v-else-if="
                                                                field.currency
                                                            "
                                                        />
                                                        <i
                                                            class="fa fa-image"
                                                            v-else-if="
                                                                field.type ===
                                                                'image'
                                                            "
                                                        />
                                                        <span
                                                            v-else
                                                            v-html="
                                                                fieldIconList[
                                                                    field.type
                                                                ]
                                                            "
                                                            style="
                                                                display: inline-block;
                                                                height: 14px;
                                                                min-width: 12px;
                                                            "
                                                        ></span>
                                                        <span
                                                            class="
                                                                inline-block
                                                                ml-3
                                                            "
                                                            style="
                                                                color: #595959;
                                                            "
                                                            >{{
                                                                field.name
                                                            }}</span
                                                        >
                                                    </div>
                                                </div>
                                            </template>

                                            <template
                                                v-else-if="
                                                    rightBarSelected ===
                                                    'staticFields'
                                                "
                                            >
                                                <div>
                                                    <div
                                                        :data-field-value="
                                                            field.isPremium
                                                                ? `Premium: ${field.value}`
                                                                : field.value
                                                        "
                                                        v-for="field in staticFields"
                                                        :key="field.value"
                                                        class="
                                                            my-1
                                                            cursor-pointer
                                                            each-field
                                                            py-2
                                                            pl-2
                                                        "
                                                        style="
                                                            overflow: hidden;
                                                            text-overflow: ellipsis;
                                                            white-space: nowrap;
                                                        "
                                                        :title="field.name"
                                                        :class="
                                                            draggingField ===
                                                            field.value
                                                                ? 'field-drag'
                                                                : ''
                                                        "
                                                    >
                                                        <i
                                                            class="fa fa-image"
                                                            v-if="
                                                                field.type ===
                                                                'image'
                                                            "
                                                            :style="{
                                                                color: field.color,
                                                            }"
                                                        ></i>
                                                        <span
                                                            v-else
                                                            v-html="
                                                                fieldIconList[
                                                                    field.type
                                                                ]
                                                            "
                                                            style="
                                                                display: inline-block;
                                                                height: 14px;
                                                                min-width: 12px;
                                                            "
                                                            :style="{
                                                                color: field.color,
                                                            }"
                                                        ></span>
                                                        <span
                                                            class="
                                                                inline-block
                                                                ml-3
                                                            "
                                                            >{{
                                                                field.name
                                                            }}</span
                                                        >
                                                    </div>
                                                </div>
                                            </template>

                                            <template
                                                v-else-if="
                                                    rightBarSelected ===
                                                    'customFields'
                                                "
                                            >
                                                <div>
                                                    <div
                                                        :data-field-value="
                                                            field.value
                                                        "
                                                        v-for="(
                                                            field, fieldI
                                                        ) of customFields"
                                                        :key="fieldI"
                                                        class="
                                                            my-1
                                                            cursor-pointer
                                                            each-field
                                                            py-2
                                                            pl-2
                                                            dF
                                                            jSB
                                                            custom-field-item
                                                        "
                                                        :class="
                                                            draggingField ===
                                                            field.value
                                                                ? 'field-drag'
                                                                : ''
                                                        "
                                                    >
                                                        <div class="handle f1">
                                                            <i
                                                                class="
                                                                    fa fa-image
                                                                "
                                                                v-if="
                                                                    field.type ===
                                                                    'image'
                                                                "
                                                                :style="{
                                                                    color: field.color,
                                                                }"
                                                            ></i>
                                                            <span
                                                                v-else
                                                                v-html="
                                                                    fieldIconList[
                                                                        'text'
                                                                    ]
                                                                "
                                                                style="
                                                                    display: inline-block;
                                                                    height: 14px;
                                                                    min-width: 12px;
                                                                "
                                                                :style="{
                                                                    color: field.color,
                                                                }"
                                                            ></span>
                                                            <span
                                                                class="
                                                                    inline-block
                                                                    ml-3
                                                                "
                                                                >{{
                                                                    field.name
                                                                }}</span
                                                            >
                                                        </div>
                                                        <div
                                                            class="
                                                                hover:text-danger
                                                                ml-4
                                                                custom-field__edit
                                                            "
                                                            @click="
                                                                deleteCustomField(
                                                                    field.value
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="
                                                                    fa fa-trash
                                                                "
                                                            ></i>
                                                        </div>
                                                        <div
                                                            class="
                                                                hover:text-primary
                                                                ml-4
                                                                custom-field__edit
                                                            "
                                                            @click="
                                                                editCustomField(
                                                                    field.value
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="
                                                                    fa fa-gear
                                                                "
                                                            ></i>
                                                        </div>
                                                    </div>
                                                    <a-divider
                                                        v-if="
                                                            Object.keys(
                                                                customFields
                                                            ).length
                                                        "
                                                    />
                                                    <div
                                                        class="
                                                            cursor-pointer
                                                            hover:text-primary
                                                        "
                                                        @click="
                                                            editField.id = null;editField.show = true
                                                        "
                                                    >
                                                        <a-icon
                                                            style="
                                                                color: var(
                                                                    --primary
                                                                );
                                                            "
                                                            type="plus"
                                                            class="mr-2"
                                                        />
                                                        Add Custom Field
                                                    </div>
                                                </div>
                                            </template>

                                            <template
                                                v-else-if="
                                                    rightBarSelected === 'edit'
                                                "
                                            >
                                                <div
                                                    class="field-options-footer"
                                                    :class="
                                                        Boolean(selectedField)
                                                            ? ''
                                                            : 'dF jC aC muted-colors mt-4'
                                                    "
                                                >
                                                    <div
                                                        class="text-center"
                                                        v-if="
                                                            !Boolean(
                                                                selectedField
                                                            )
                                                        "
                                                    >
                                                        <a-icon
                                                            type="gateway"
                                                        />
                                                        <small
                                                            class="mt-3 block"
                                                            >Please select a
                                                            Field to edit</small
                                                        >
                                                    </div>
                                                    <div v-else>
                                                        <div
                                                            class="
                                                                ant-m-header
                                                                py-3
                                                            "
                                                        >
                                                            <span
                                                                :style="`display:inline-block; height:10px; width:3px; background:${selectedField.text.text_color}; `"
                                                                class="mr-2"
                                                            ></span>
                                                            <strong
                                                                v-if="
                                                                    selectedField
                                                                        .field
                                                                        .custom
                                                                "
                                                                >Custom Field:
                                                            </strong>
                                                            <strong
                                                                v-else-if="
                                                                    selectedField
                                                                        .field
                                                                        .static
                                                                "
                                                                >Static Field:
                                                            </strong>
                                                            <strong v-else
                                                                >{{
                                                                    selectedField
                                                                        .field
                                                                        .group
                                                                }}:
                                                            </strong>
                                                            {{
                                                                selectedField
                                                                    .field.name
                                                            }}
                                                            <small
                                                                class="
                                                                    block
                                                                    mt-1
                                                                "
                                                                v-if="
                                                                    selectedField.field.value.includes(
                                                                        'contactX.'
                                                                    )
                                                                "
                                                                >This Purchaser
                                                                will be selected
                                                                upon generating
                                                                the
                                                                Document.</small
                                                            >
                                                        </div>

                                                        <a-form
                                                            v-if="
                                                                selectedField
                                                                    .field
                                                                    .deposit
                                                            "
                                                        >
                                                            <a-form-item
                                                                label="Deposit Number"
                                                            >
                                                                <a-select
                                                                    :value="
                                                                        selectedField.field.name.startsWith(
                                                                            'AddOn Deposit'
                                                                        )
                                                                            ? selectedField
                                                                                  .text
                                                                                  .addOnDeposits
                                                                            : selectedField
                                                                                  .text
                                                                                  .deposits
                                                                    "
                                                                    @change="
                                                                        applyFont(
                                                                            $event,
                                                                            selectedField.field.name.startsWith(
                                                                                'AddOn Deposit'
                                                                            )
                                                                                ? 'addOnDeposits'
                                                                                : 'deposits'
                                                                        )
                                                                    "
                                                                >
                                                                    <a-select-option
                                                                        :value="
                                                                            i +
                                                                            1
                                                                        "
                                                                        v-for="(
                                                                            e, i
                                                                        ) in new Array(
                                                                            20
                                                                        )"
                                                                        :key="`deposit ${i}`"
                                                                        >Deposit
                                                                        {{
                                                                            i +
                                                                            1
                                                                        }}</a-select-option
                                                                    >
                                                                </a-select>
                                                            </a-form-item>
                                                        </a-form>

                                                        <a-form v-if="selectedField.field.value.includes('purchasers[].') && selectedField.field.group !== 'Tarion'" >
                                                            <a-form-item label="Purchaser Number">
                                                                <a-select
                                                                    :value="selectedField.text.purchasers"
                                                                    @change="applyFont($event,'purchasers')"
                                                                >
                                                                    <a-select-option
																		v-for="(e, i) in new Array(5)"
                                                                        :value="i + 1"
                                                                        :key="`purchaser ${i}`"
                                                                    >
																		Purchaser {{ i + 1 }}
																	</a-select-option>
                                                                </a-select>
                                                            </a-form-item>
                                                        </a-form>

														<a-form v-if="selectedField.field.value.includes('purchaserX[].') && selectedField.field.group !== 'Tarion'" >
															<a-form-item item label="PurchaserX Number">
																<a-select
                                                                    :value="selectedField.text.purchaserX"
                                                                    @change="applyFont($event,'purchaserX')"
                                                                >
                                                                    <a-select-option
																		v-for="(e, i) in new Array(5)"
                                                                        :value="i + 1"
                                                                        :key="`purchaser ${i}`"
                                                                    >
																		Dynamic Purchaser {{ i + 1 }}
																	</a-select-option>
                                                                </a-select>
															</a-form-item>
														</a-form>

                                                        <a-form
                                                            v-if="
                                                                selectedField.field.value.includes(
                                                                    'signingParty[].'
                                                                )
                                                            "
                                                        >
                                                            <a-form-item
                                                                label="Signing Officer Number"
                                                            >
                                                                <a-select
                                                                    :value="
                                                                        selectedField
                                                                            .text
                                                                            .signingParty
                                                                    "
                                                                    @change="
                                                                        applyFont(
                                                                            $event,
                                                                            'signingParty'
                                                                        )
                                                                    "
                                                                >
                                                                    <a-select-option
                                                                        v-for="(
                                                                            e, i
                                                                        ) of new Array(
                                                                            20
                                                                        )"
                                                                        :key="`field signing officer ${i}`"
                                                                        :value="
                                                                            i +
                                                                            1
                                                                        "
                                                                    >
                                                                        <span
                                                                            v-if="
                                                                                i ===
                                                                                0
                                                                            "
                                                                            >Main
                                                                            Signing
                                                                            Party</span
                                                                        >
                                                                        <span
                                                                            v-else
                                                                            >Additional
                                                                            Signing
                                                                            Party
                                                                            {{
                                                                                i
                                                                            }}</span
                                                                        >
                                                                    </a-select-option>
                                                                </a-select>
                                                            </a-form-item>
                                                        </a-form>

														<a-form v-if="selectedField.field.value.includes('sellersAgents[].') && !selectedField.field.value.includes('sellersAgents[].company')">
                                                            <a-form-item label="Seller's Agent Number">
                                                                <a-select
                                                                    :value="selectedField.text.sellersAgents"
                                                                    @change="applyFont($event,'sellersAgents')"
                                                                >
                                                                    <a-select-option
																		v-for="(e, i) of new Array(5)"
                                                                        :key="`field seller's agent ${i}`"
                                                                        :value="i + 1"
                                                                    >
                                                                        <span v-if="i === 0" >
																			Main Seller's Agent
																		</span>
                                                                        <span v-else>
																			Additional Seller's Agent {{i}}
																		</span>
                                                                    </a-select-option>
                                                                </a-select>
                                                            </a-form-item>
                                                        </a-form>

                                                        <a-form v-if="selectedField.field.static">
                                                            <div v-if="selectedField.field.type == 'text'" class="mb-3" >
                                                                <div>
                                                                    Static Value
                                                                </div>
                                                                <a-textarea
                                                                    v-model="selectedField.field.staticValue"
                                                                    :auto-size="{
                                                                        minRows: 4,
                                                                        maxRows: 12,
                                                                    }"
                                                                    @change="
                                                                        changeStaticValue(
                                                                            selectedField
                                                                                .field
                                                                                .staticValue
                                                                        )
                                                                    "
                                                                />
                                                            </div>

                                                            <div
                                                                v-else-if="
                                                                    selectedField
                                                                        .field
                                                                        .type ==
                                                                    'image'
                                                                "
                                                                class="mb-3"
                                                            >
                                                                <div>
                                                                    Set Featured
                                                                    Image
                                                                </div>
                                                                <ImageBoxSelector
                                                                    :plus="true"
                                                                    v-model="
                                                                        selectedField
                                                                            .field
                                                                            .staticValue
                                                                    "
                                                                    :removable="
                                                                        true
                                                                    "
                                                                    @input="
                                                                        changeStaticValue
                                                                    "
                                                                />
                                                            </div>
                                                        </a-form>

                                                        <a-form
                                                            v-if="
                                                                [
                                                                    'text',
                                                                    'multiple',
                                                                    'date',
                                                                    'inclusion',
																	'variable'
                                                                ].includes(
                                                                    selectedField
                                                                        .field
                                                                        .type
                                                                )
                                                            "
                                                        >
                                                            <div class="mb-3">
                                                                <p>
                                                                    Format Text
                                                                </p>
                                                                <a-select
                                                                    :value="
                                                                        selectedField
                                                                            .text
                                                                            .font_family
                                                                    "
                                                                    @change="
                                                                        applyFont(
                                                                            $event,
                                                                            'font_family'
                                                                        )
                                                                    "
                                                                >
                                                                    <a-select-option
                                                                        value="Courier"
                                                                        key="1"
                                                                        >Courier</a-select-option
                                                                    >
                                                                    <a-select-option
                                                                        value="Times New Roman"
                                                                        key="2"
                                                                        >Times
                                                                        New
                                                                        Roman</a-select-option
                                                                    >
                                                                    <a-select-option
                                                                        value="Helvetica"
                                                                        key="3"
                                                                        >Helvetica</a-select-option
                                                                    >
                                                                </a-select>
                                                            </div>
                                                            <div
                                                                class="
                                                                    mb-4
                                                                    w-1/2
                                                                "
                                                            >
                                                                <a-select
                                                                    :value="
                                                                        selectedField
                                                                            .text
                                                                            .font_size
                                                                    "
                                                                    @change="
                                                                        applyFont(
                                                                            $event,
                                                                            'font_size'
                                                                        )
                                                                    "
                                                                >
                                                                    <a-select-option
                                                                        :value="
                                                                            8
                                                                        "
                                                                        >8</a-select-option
                                                                    >
                                                                    <a-select-option
                                                                        :value="
                                                                            9
                                                                        "
                                                                        >9</a-select-option
                                                                    >
                                                                    <a-select-option
                                                                        :value="
                                                                            10
                                                                        "
                                                                        >10</a-select-option
                                                                    >
                                                                    <a-select-option
                                                                        :value="
                                                                            11
                                                                        "
                                                                        >11</a-select-option
                                                                    >
                                                                    <a-select-option
                                                                        :value="
                                                                            12
                                                                        "
                                                                        >12</a-select-option
                                                                    >
                                                                    <a-select-option
                                                                        :value="
                                                                            14
                                                                        "
                                                                        >14</a-select-option
                                                                    >
                                                                    <a-select-option
                                                                        :value="
                                                                            18
                                                                        "
                                                                        >18</a-select-option
                                                                    >
                                                                </a-select>
                                                            </div>

                                                            <div
                                                                class="
                                                                    dF
                                                                    aC
                                                                    mb-4
                                                                "
                                                            >
                                                                <span
                                                                    style="
                                                                        display: inline-block;
                                                                        cursor: pointer;
                                                                    "
                                                                    @click.prevent="
                                                                        applyFont(
                                                                            !selectedField
                                                                                .text
                                                                                .bold,
                                                                            'bold'
                                                                        )
                                                                    "
                                                                    :class="
                                                                        selectedField
                                                                            .text
                                                                            .bold
                                                                            ? ''
                                                                            : 'text-med-gray'
                                                                    "
                                                                >
                                                                    <svg
                                                                        style="
                                                                            fill: currentColor;
                                                                            width: 15px;
                                                                        "
                                                                        viewBox="0 0 16.175 19.746"
                                                                    >
                                                                        <path
                                                                            d="M15.171,11.33a5.377,5.377,0,0,0,1.19-2.874,5.645,5.645,0,0,0-5.6-6.206H1.971a.705.705,0,0,0-.705.705V5.071a.705.705,0,0,0,.705.705h1.4V18.47h-1.4a.705.705,0,0,0-.705.705v2.116A.705.705,0,0,0,1.971,22H11.2A6.164,6.164,0,0,0,17.412,16.6,5.972,5.972,0,0,0,15.171,11.33ZM6.892,5.776h3.868a2.116,2.116,0,1,1,0,4.231H6.892ZM10.761,18.47H6.892V13.534h3.868a2.468,2.468,0,1,1,0,4.937Z"
                                                                            transform="translate(-1.266 -2.25)"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    style="
                                                                        display: inline-block;
                                                                        cursor: pointer;
                                                                    "
                                                                    class="ml-3"
                                                                    @click.prevent="
                                                                        applyFont(
                                                                            !selectedField
                                                                                .text
                                                                                .italic,
                                                                            'italic'
                                                                        )
                                                                    "
                                                                    :class="
                                                                        selectedField
                                                                            .text
                                                                            .italic
                                                                            ? ''
                                                                            : 'text-med-gray'
                                                                    "
                                                                >
                                                                    <svg
                                                                        style="
                                                                            fill: currentColor;
                                                                            width: 15px;
                                                                        "
                                                                        viewBox="0 0 14.75 20.65"
                                                                    >
                                                                        <path
                                                                            d="M14.75,2.987V4.463a.737.737,0,0,1-.738.737H11.12L7.432,19.95H9.587a.738.738,0,0,1,.738.738v1.475a.738.738,0,0,1-.738.738H.738A.738.738,0,0,1,0,22.162V20.688a.738.738,0,0,1,.738-.738H3.63L7.318,5.2H5.162a.737.737,0,0,1-.738-.737V2.987a.738.738,0,0,1,.738-.737h8.85A.737.737,0,0,1,14.75,2.987Z"
                                                                            transform="translate(0 -2.25)"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    style="
                                                                        display: inline-block;
                                                                        cursor: pointer;
                                                                    "
                                                                    class="ml-3"
                                                                    @click.prevent="
                                                                        applyFont(
                                                                            !selectedField
                                                                                .text
                                                                                .underline,
                                                                            'underline'
                                                                        )
                                                                    "
                                                                    :class="
                                                                        selectedField
                                                                            .text
                                                                            .underline
                                                                            ? ''
                                                                            : 'text-med-gray'
                                                                    "
                                                                >
                                                                    <svg
                                                                        style="
                                                                            fill: currentColor;
                                                                            width: 15px;
                                                                        "
                                                                        viewBox="0 0 17.85 20.4"
                                                                    >
                                                                        <path
                                                                            d="M1.275,2.55H2.55V8.925a6.375,6.375,0,0,0,12.75,0V2.55h1.275a.638.638,0,0,0,.638-.638V.638A.638.638,0,0,0,16.575,0H10.838A.638.638,0,0,0,10.2.638V1.913a.638.638,0,0,0,.638.638h1.275V8.925a3.188,3.188,0,1,1-6.375,0V2.55H7.013a.638.638,0,0,0,.638-.638V.638A.638.638,0,0,0,7.013,0H1.275A.638.638,0,0,0,.638.638V1.913a.638.638,0,0,0,.638.638Zm15.938,15.3H.638A.638.638,0,0,0,0,18.488v1.275a.638.638,0,0,0,.638.638H17.213a.638.638,0,0,0,.638-.638V18.488A.638.638,0,0,0,17.213,17.85Z"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            </div>
                                                            <a-form
                                                                v-if="
                                                                    [
                                                                        'date',
                                                                    ].includes(
                                                                        selectedField
                                                                            .field
                                                                            .type
                                                                    )
                                                                "
                                                            >
                                                                <a-form-item
                                                                    label="Date Format"
                                                                >
                                                                    <a-select
                                                                        :value="
                                                                            selectedField
                                                                                .text
                                                                                .dateFormat
                                                                        "
                                                                        @change="
                                                                            applyFont(
                                                                                $event,
                                                                                'dateFormat'
                                                                            )
                                                                        "
                                                                    >
                                                                        <a-select-option
                                                                            value="L/d/yyyy"
                                                                            key="1"
                                                                            >Default
                                                                            eg.
                                                                            (1/31/2020)</a-select-option
                                                                        >
																		<a-select-option
                                                                            value="d/L/yyyy"
                                                                            key="1"
                                                                            >Alternative
                                                                            #1
                                                                            (31/1/2020)</a-select-option
                                                                        >
                                                                        <a-select-option
                                                                            value="yyyy/L/d"
                                                                            key="2"
                                                                            >Alternative
                                                                            #2
                                                                            (2020/1/31)</a-select-option
                                                                        >
                                                                        <a-select-option
                                                                            value="LLL/d/yyyy"
                                                                            key="3"
                                                                            >Alternative
                                                                            #3
                                                                            (Jan/31/2020)</a-select-option
                                                                        >
																		<a-select-option
                                                                            value="MMMM d, yyyy"
                                                                            key="4"
                                                                            >Alternative
                                                                            #4
                                                                            (January 31, 2020)</a-select-option
                                                                        >
																		<a-select-option
                                                                            value="d MMMM, yyyy"
                                                                            key="5"
                                                                            >Alternative
                                                                            #5
                                                                            (31 January, 2020)</a-select-option
                                                                        >
                                                                        <a-select-option
                                                                            value="eee"
                                                                            key="6"
                                                                            >Short
                                                                            Day
                                                                            (Mon)</a-select-option
                                                                        >
                                                                        <a-select-option
                                                                            value="eeee"
                                                                            key="7"
                                                                            >Full
                                                                            Day
                                                                            (Monday)</a-select-option
                                                                        >
                                                                        <a-select-option
                                                                            value="LLL"
                                                                            key="8"
                                                                            >Short
                                                                            Month
                                                                            (Jan)</a-select-option
                                                                        >
                                                                        <a-select-option
                                                                            value="LLLL"
                                                                            key="9"
                                                                            >Full
                                                                            Month
                                                                            (January)</a-select-option
                                                                        >
                                                                        <a-select-option
                                                                            value="d"
                                                                            key="10"
                                                                            >Date
                                                                            (31)</a-select-option
                                                                        >
                                                                        <a-select-option
                                                                            value="do"
                                                                            key="11"
                                                                            >Full
                                                                            Date
                                                                            (31st)</a-select-option
                                                                        >
                                                                        <a-select-option
                                                                            value="yyyy"
                                                                            key="12"
                                                                            >Year
                                                                            (2020)</a-select-option
                                                                        >
                                                                        <a-select-option
                                                                            value="yy"
                                                                            key="13"
                                                                            >Short
                                                                            Year
                                                                            (20)</a-select-option
                                                                        >
                                                                    </a-select>
                                                                </a-form-item>
                                                            </a-form>
                                                            <div
                                                                class="mb-4 w-1/2"
                                                                v-if="selectedField.field.currency"
                                                            >
                                                                <a-form-item
                                                                    label="Enable dollar sign ($) in price ?"
                                                                >
                                                                    <a-switch v-model="selectedField.text.showDollarSign"/>
                                                                </a-form-item>
                                                            </div>
                                                            <div
                                                                class="
                                                                    mb-4
                                                                    w-1/2
                                                                "
                                                            >
                                                                <a-form-item
                                                                    label="Enable Boundaries ?"
                                                                >
                                                                    <a-switch
                                                                        v-model="
                                                                            selectedField
                                                                                .text
                                                                                .boundaries
                                                                        "
                                                                        @change="
                                                                            applyFont(
                                                                                $event,
                                                                                'boundaries'
                                                                            )
                                                                        "
                                                                    />
                                                                </a-form-item>
                                                            </div>
                                                        </a-form>

                                                        <a-form
                                                            v-if="
                                                                selectedField
                                                                    .field
                                                                    .type ===
                                                                'checkmark'
                                                            "
                                                        >
                                                            <a-form-item
                                                                label="Size"
                                                            >
                                                                <a-select
                                                                    :value="
                                                                        selectedField.size
                                                                    "
                                                                    @change="
                                                                        applyOption(
                                                                            $event,
                                                                            'size'
                                                                        )
                                                                    "
                                                                >
                                                                    <a-select-option
                                                                        :value="
                                                                            1
                                                                        "
                                                                        >Regular</a-select-option
                                                                    >
                                                                    <a-select-option
                                                                        :value="
                                                                            2
                                                                        "
                                                                        >Medium</a-select-option
                                                                    >
                                                                    <a-select-option
                                                                        :value="
                                                                            3
                                                                        "
                                                                        >Large</a-select-option
                                                                    >
                                                                </a-select>
                                                            </a-form-item>
                                                        </a-form>

														<a-form>
															<a-form-item
                                                                label="Repeat this field on selected pages"
                                                            >
																<a-select
																	:value="selectedField.repeat"
                                                                    @change="applyFieldRepeat"
								                                    :options="pagesOptions"
																	mode="multiple"
								                                />
                                                            </a-form-item>

															<a-form-item
																v-if="selectedField.field.type === 'image'"
                                                                label="Image Render Size"
                                                            >
																<a-select placeholder="Image Size" style="width: 100%" v-model="selectedField.renderSize">
																	<a-select-option v-for="size in imageSizeList" :key="size.value" :value="size.value">
																		{{ size.label }}
																	</a-select-option>
																</a-select>
                                                            </a-form-item>

															<a-form-item
																v-if="selectedField.field.type === 'image'"
                                                                label="Image Alignment"
                                                            >
																<a-select placeholder="Image Alignment" style="width: 100%" v-model="selectedField.alignment">
																	<a-select-option v-for="alignment in imageAlignmentList" :key="alignment.value" :value="alignment.value">
																		{{ alignment.label }}
																	</a-select-option>
																</a-select>
                                                            </a-form-item>
														</a-form>

                                                        <div v-if="selectedField.field.type === 'signedDate'" class="mb-3">
                                                            <a-alert type="info" show-icon>
                                                                <template #message>
                                                                    <div>
                                                                        All signed date formatting is managed directly by DocuSign. To adjust the date format, please visit the DocuSign portal.
                                                                        <a class="link" href="https://support.docusign.com/s/articles/How-do-I-include-a-time-stamp-along-with-the-date-signed-on-document?language=en_US" target="_blank"> Click here</a> for more details.
                                                                    </div>
                                                                </template>
                                                            </a-alert>
                                                        </div>

                                                        <a-button
                                                            @click="deleteField"
                                                            type="danger"
                                                            icon="delete"
                                                            >Delete
                                                            Field</a-button
                                                        >
                                                    </div>
                                                </div>
                                            </template>

											<template
                                                v-else-if="
                                                    rightBarSelected ===
                                                    'options'
                                                "
                                            >
												<a-divider style="margin: 12px 0"/>
                                                <div class="my-3">
													<a-form-item>
														<template slot="label">
															<h5>
																Deposit Structure &nbsp;
																<a-tooltip overlayClassName="change-tooltip-color">
																	<template slot="title">
																		Select the Deposit Structure you want to use in transaction creation process.
																		<br>
																		Note: If no deposit structure has been selected it will use the first default deposit structure in the transaction.
																	</template>
																	<a-icon type="question-circle"
																		style="font-size: 14px; color: black; margin-left: 2px;" />
																</a-tooltip>
															</h5>
														</template>
			                                            <a-select v-model="templateOptions.depositStructure" style="width: 100%;" option-label-prop="label" allowClear>
			                                                <a-select-option v-for="( deposit, index ) of deposits" :key="index" :value="deposit.id" :label="deposit.name">
																{{ deposit.name }} &nbsp; ({{ deposit.items && deposit.items.length }} Deposits)
			                                                </a-select-option>
			                                            </a-select>
			                                        </a-form-item>
                                                </div>
                                                <a-divider style="margin: 12px 0"/>
                                                <div class="my-4" v-if="templateOptions.doNotSendCopyTo">
													<h5>
                                                        Don't Send a Copy To
                                                        <a-tooltip overlayClassName="change-tooltip-color">
															<template slot="title">
																If a participant is excluded from signing, ensure there are no signature fields on the contract requiring that participant's signature.
															</template>
															<a-icon type="question-circle"
																style="font-size: 14px; color: black; margin-left: 2px;" />
														</a-tooltip>
                                                    </h5>
                                                    <a-row :gutter="[16, 16]" class="mt-2 pr-2">
                                                        <a-col :span="12">
                                                            <div>Purchaser</div>
                                                            <a-switch v-model="templateOptions.doNotSendCopyTo.purchaser"/>
                                                        </a-col>
                                                        <a-col :span="12">
                                                            <div>Lawyer</div>
                                                            <a-switch v-model="templateOptions.doNotSendCopyTo.lawyer"/>
                                                        </a-col>
                                                        <a-col :span="12">
                                                            <div>Agent</div>
                                                            <a-switch v-model="templateOptions.doNotSendCopyTo.agent"/>
                                                        </a-col>
                                                        <a-col :span="12">
                                                            <div>Seller</div>
                                                            <a-switch v-model="templateOptions.doNotSendCopyTo.seller"/>
                                                        </a-col>
                                                    </a-row>
												</div>
                                                <a-divider style="margin: 12px 0"/>
												<div class="my-4" v-if="instance.productType === 'lowrise' && templateOptions.filterCriteria">
													<h5>Default Template Selection Criteria</h5>
                                                    <div class="mt-2 pr-2" v-for="item in filterCriteriaList" :key="item.listKey">
                                                        <div>{{ item.label }}:</div>
                                                        <div class="dF mt-2" style="flex-wrap: wrap;">
                                                            <a-button
                                                                v-for="value in modelDetails[item.listKey]"
                                                                :key="value"
                                                                class="mb-4 mr-4"
                                                                :type="templateOptions.filterCriteria[item.criteriaKey] === value ? 'primary' : 'default'"
                                                                @click="onTemplateCriteriaChange(item.criteriaKey, value)"
                                                            >
                                                                {{ value }}
                                                            </a-button>
                                                        </div>
                                                    </div>
												</div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="rightBarToggle cursor-pointer"
                                style="
                                    border-top: 1px solid var(--light-purple);
                                "
                            >
                                <a-tooltip
                                    title="Organize Pages"
                                    placement="left"
									overlayClassName="change-tooltip-color"
                                >
                                    <div
                                        class="right-toolbar-item py-3"
                                        :class="
                                            rightBarSelected === 'pages'
                                                ? 'active'
                                                : ''
                                        "
                                        @click="rightBarSelected = 'pages'"
                                    >
                                        <i class="fa fa-file"></i>
                                    </div>
                                </a-tooltip>

                                <a-tooltip title="Fields" placement="left" overlayClassName="change-tooltip-color">
                                    <div
                                        class="right-toolbar-item py-3"
                                        :class="
                                            rightBarSelected === 'fields'
                                                ? 'active'
                                                : ''
                                        "
                                        @click="rightBarSelected = 'fields'"
                                    >
                                        <i class="fa fa-list-ul"></i>
                                    </div>
                                </a-tooltip>

                                <a-tooltip
                                    title="Static Fields"
                                    placement="left"
									overlayClassName="change-tooltip-color"
                                >
                                    <div
                                        class="right-toolbar-item py-3"
                                        :class="
                                            rightBarSelected === 'staticFields'
                                                ? 'active'
                                                : ''
                                        "
                                        @click="
                                            rightBarSelected = 'staticFields'
                                        "
                                    >
                                        <a-icon
                                            type="thunderbolt"
                                            theme="filled"
                                        />
                                    </div>
                                </a-tooltip>

                                <a-tooltip
                                    title="Custom Fields"
                                    placement="left"
									overlayClassName="change-tooltip-color"
                                >
                                    <div
                                        class="right-toolbar-item py-3"
                                        :class="
                                            rightBarSelected === 'customFields'
                                                ? 'active'
                                                : ''
                                        "
                                        @click="
                                            rightBarSelected = 'customFields'
                                        "
                                    >
                                        <i class="fa fa-bookmark"></i>
                                    </div>
                                </a-tooltip>

                                <a-tooltip title="Edit Field" placement="left" overlayClassName="change-tooltip-color">
                                    <div
                                        class="right-toolbar-item py-3"
                                        :class="
                                            rightBarSelected === 'edit'
                                                ? 'active'
                                                : ''
                                        "
                                        @click="rightBarSelected = 'edit'"
                                    >
                                        <i class="fa fa-pencil"></i>
                                    </div>
                                </a-tooltip>

								<a-tooltip title="Template Options" placement="left" overlayClassName="change-tooltip-color">
                                    <div
                                        class="right-toolbar-item py-3"
                                        :class="
                                            rightBarSelected === 'options'
                                                ? 'active'
                                                : ''
                                        "
                                        @click="rightBarSelected = 'options'"
                                    >
										<a-icon type="setting" theme="filled" />
                                    </div>
                                </a-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import ImageBoxSelector from "bh-mod/components/common/ImageBoxSelector";
import draggable from "vuedraggable";
import { setProp, deleteProp } from "bh-mod";
import Icons from "@/components/common/BHIcons";
import InsertModal from "@/components/forms/InsertModal";
import ShuffleModal from "@/components/forms/ShuffleModal";
import PageButtons from "bh-mod/components/layout/SubBarGlobal/PageButtons";
let func = (e) => {
    var confirmationMessage =
        "It looks like you have been editing something. " +
        "If you leave before saving, your changes will be lost.";

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
};

export default {
    name: "ContractEditor",
    components: {
        PageButtons,
        ImageBoxSelector,
        Icons,
        InsertModal,
        ShuffleModal,
        draggable,
    },
    data() {
        let req = (message, realtime) => ({
            required: true,
            message,
            trigger: realtime ? "change" : "",
        });

        return {
            lastFormat: {
                font_family: "Times New Roman",
                font_size: 8,
                bold: false,
                italic: false,
                underline: false,
            },
            needsDelete: false,
            contract: null,
            insertPagesModal: {
                index: 0,
                show: false,
            },
            shufflePageModal: {
                index: 0,
                show: false,
            },
            dupFieldName: false,
            draggingFieldEvent: false,
            droppingFieldEvent: false,
            canvasWidth: 589,
            canvasHeight: 0,
            firstTime: false,
            pagePreview: false,
            fields: [],
            editField: {
                show: false,
                id: "",
            },
            customFields: {},
            rules: {
                name: [req("Name is required")],
                type: [req("Please select a type", true)],
            },
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 14 },
            },
            pageUpdating: true,
            newCustomField: {
                name: "",
                help: "",
                type: undefined,
                configure: {
                    options: [{ value: "" }, { value: "" }],
                    defaultValue: "",
                },
            },
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 4 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 20 },
                },
            },

            customFieldDialog: false,
            newPages: [],
            fieldIconList: {
                text: `<svg style="fill:currentColor" viewBox="0 0 18.24 14.4"><path d="M3.75,6V8.88h4.8V20.4h2.88V8.88h4.8V6Zm18.24,4.8H13.35v2.88h2.88V20.4h2.88V13.68h2.88Z" transform="translate(-3.75 -6)"/></svg>`,
                date: `<svg style="fill:currentColor" viewBox="0 0 18.24 14.4"><path d="M3.75,6V8.88h4.8V20.4h2.88V8.88h4.8V6Zm18.24,4.8H13.35v2.88h2.88V20.4h2.88V13.68h2.88Z" transform="translate(-3.75 -6)"/></svg>`,
                multiple: `<svg style="fill:currentColor" viewBox="0 0 18.24 14.4"><path d="M3.75,6V8.88h4.8V20.4h2.88V8.88h4.8V6Zm18.24,4.8H13.35v2.88h2.88V20.4h2.88V13.68h2.88Z" transform="translate(-3.75 -6)"/></svg>`,
                sign: `<svg style="fill:currentColor" viewBox="0 0 12.8 17.066"><path d="M7.467,4.533V0H.8A.8.8,0,0,0,0,.8V16.266a.8.8,0,0,0,.8.8H12a.8.8,0,0,0,.8-.8V5.333H8.267A.8.8,0,0,1,7.467,4.533ZM2.133,2.4A.267.267,0,0,1,2.4,2.133H5.067a.267.267,0,0,1,.267.267v.533a.267.267,0,0,1-.267.267H2.4a.267.267,0,0,1-.267-.267Zm0,2.133A.267.267,0,0,1,2.4,4.267H5.067a.267.267,0,0,1,.267.267v.533a.267.267,0,0,1-.267.267H2.4a.267.267,0,0,1-.267-.267ZM8.56,12.8h1.573a.533.533,0,0,1,0,1.067H8.56a1.433,1.433,0,0,1-1.288-.8.369.369,0,0,0-.339-.217.364.364,0,0,0-.334.206l-.256.511a.533.533,0,0,1-.477.295l-.038,0a.531.531,0,0,1-.468-.363L4.8,11.819l-.354,1.063a1.439,1.439,0,0,1-1.367.984H2.667a.533.533,0,0,1,0-1.067H3.08a.374.374,0,0,0,.355-.255l.606-1.821a.8.8,0,0,1,1.518,0l.463,1.388a1.5,1.5,0,0,1,2.2.472A.38.38,0,0,0,8.56,12.8Zm4.006-9.3L9.3.233A.8.8,0,0,0,8.737,0h-.2V4.267H12.8v-.2A.8.8,0,0,0,12.566,3.5Z" /></svg> `,
                initial: `<svg style="fill:currentColor" viewBox="0 0 19.2 17.066"><path d="M7.272,14.138a.369.369,0,0,0-.339-.217.364.364,0,0,0-.334.206l-.256.511a.533.533,0,0,1-.983-.07L4.8,12.886l-.354,1.063a1.439,1.439,0,0,1-1.367.984H2.667a.533.533,0,0,1,0-1.067H3.08a.374.374,0,0,0,.355-.255l.606-1.821a.8.8,0,0,1,1.518,0l.463,1.388a1.5,1.5,0,0,1,2.2.472.378.378,0,0,0,.312.209V11.121L12.8,6.885V5.333H8.267a.8.8,0,0,1-.8-.8V0H.8A.8.8,0,0,0,0,.8V16.266a.8.8,0,0,0,.8.8H12a.8.8,0,0,0,.8-.8V14.933l-4.267,0A1.431,1.431,0,0,1,7.272,14.138ZM12.8,4.063a.8.8,0,0,0-.233-.563L9.3.233A.8.8,0,0,0,8.737,0h-.2V4.267H12.8Zm-3.2,7.5v2.3h2.3l5.389-5.426L15.026,6.178,9.6,11.565Zm9.351-5.988L17.889,4.515a.849.849,0,0,0-1.2,0l-.908.908,2.263,2.263.908-.908A.848.848,0,0,0,18.951,5.578Z"/></svg>`,
                signedDate: `<svg style="fill:currentColor" viewBox="0 0 18.24 14.4"><path d="M3.75,6V8.88h4.8V20.4h2.88V8.88h4.8V6Zm18.24,4.8H13.35v2.88h2.88V20.4h2.88V13.68h2.88Z" transform="translate(-3.75 -6)"/></svg>`,
                checkmark: `<svg viewBox="64 64 896 896" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>`,
            },
            pagesFields: ["asdf"],
            editor: null,
            settings: null,
            timeNow: Date.now(),
            showFieldOption: false,
            fieldValue: "",
            eventSpot: null,
            eventFieldData: {},
            rightBarSelected: "fields",
            currentPage: 0,
            pages: [],
            pageModal: false,
            deletePage: null,
            moreOptionsPopover: false,
            selectedField: null,
            topFieldsGroup: "Purchaser(s)",
            purchaserNo: 1,
            purchaserXNo: 1,
            signingPartyNo: 1,
            sellersAgentsNo: 1,
            depositNo: 1,
			productType: 'lot',
            canvasPos: {},
            draggingField: null,
            fieldOptionTool: {
                fontSize: 11,
            },
            selectedTool: null,
            toolHoverMode: false,
            toolHovered: "null",
            toolHoveredTO: null,
            templateOptions: {
                depositStructure: null,
                filterCriteria: {
                    frontage: null,
                    modelType: null,
                    stories: null
                },
                doNotSendCopyTo: {
                    purchaser: false,
                    lawyer: false,
                    agent: false,
                    seller: false,
                }
            },
            modelDetails: {},
            customFieldList: [
                { label: 'Text', value: 'text' },
                { label: 'Multiple Choice', value: 'multiple' },
                { label: 'Date', value: 'date' },
                { label: 'Image', value: 'image' },
                { label: 'Checkmark', value: 'checkmark' },
                { label: 'Inclusion', value: 'inclusion' },
                { label: 'Variable Input', value: 'variable' },
            ],
            filterCriteriaList: [
                { label: 'Lot Frontage', listKey: 'frontages', criteriaKey: 'frontage' },
                { label: 'Modal Type', listKey: 'modelTypes', criteriaKey: 'modelType' },
                { label: 'Storeys', listKey: 'stories', criteriaKey: 'stories' }
            ],
			imageSizeList: [
				{ label: 'Small (Max Width 500px)', value: 'small' },
				{ label: 'Medium (Max Width 750px)', value: 'medium' },
				{ label: 'Large (Max Width 1000px)', value: 'large' },
				{ label: 'Original', value: 'original' },
			],
			imageAlignmentList: [
				{ label: 'Center', value: 'center' },
				{ label: 'Top Left', value: 'topleft' },
				{ label: 'Top Center', value: 'topcenter' },
				{ label: 'Top Right', value: 'topright' },
				{ label: 'Right', value: 'right' },
				{ label: 'Bottom Right', value: 'bottomright' },
				{ label: 'Bottom Center', value: 'bottomcenter' },
				{ label: 'Bottom Left', value: 'bottomleft' },
				{ label: 'Left', value: 'left' }
			]
        };
    },
    watch: {
        "selectedField.field.staticValue"(val) {
            if (
                this.selectedField &&
                this.selectedField.field &&
                this.selectedField.field.staticValue
            ) {
                this.selectedField.field.staticValue =
                    this.selectedField.field.staticValue.replace(
                        /[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu,
                        ""
                    );
            }
        },
        pageUpdating(val) {
            this.$store.commit("LOAD", val);
        },
        "editField.show"(show) {
			if (!show) {
				this.editField.id = null;
				this.newCustomField.value = null;
				this.resetForm("newCustomField");
			} else {
				if (this.editField.id) {
					let field = this.customFields[this.editField.id];
					if (!field.help) field.help = "";
					this.newCustomField = JSON.parse(JSON.stringify(field));
				} else {
					this.newCustomField = {
						name: "",
						help: "",
						type: undefined,
						configure: {
							options: [{ value: "" }, { value: "" }],
							defaultValue: "",
						},
					}
				}
			}
        },
		"newCustomField.type"(val) {
			if (this.editField.id) return;

			const defaultValues = {
				checkmark: false,
				inclusion: [],
				multiple: "0"
			};

			this.newCustomField.configure.defaultValue = defaultValues[val] || "";
		},

        async currentPage(val, oldVal) {
            let key = "autosaving";
            this.$message.loading({ content: "Auto Saving...", key });
			try {
				this.saveContract();
				this.$message.success({ content: "Auto Saved!", key, duration: 3.5 });
			} catch (err) {
				this.$toastError(err, `Error occurred while auto saving contract. Please try again!`)
			}

            this.gotoPage(val, oldVal);
        },
    },
    computed: {
        staticFields() {
            return this.$store.state.staticFields;
        },
        currentPageReadable: {
            get() {
                return this.currentPage + 1;
            },
            set(val) {
                this.currentPage = val - 1;
            },
        },
        toolbarItems() {
            return [
                {
                    name: "Zoom In",
                    tooltip: "Zoom in",
                    value: "zoom-in",
                    bl: false,
                    fn: () => this.editor?.zoomIn(),
                },
                {
                    name: "Zoom Out",
                    tooltip: "Zoom out",
                    value: "zoom-out",
                    bl: false,
                    fn: () => this.editor.zoomOut(),
                },
                {
                    name: "Reset Zoom",
                    tooltip: "Reset Zoom/Pan",
                    value: "zoom-reset",
                    bl: false,
                    fn: () => this.editor.zoomReset(),
                },
                {
                    name: "Rotate Clockwise",
                    tooltip: "Rotate Page Clockwise",
                    value: "rotate-cw",
                    bl: false,
                    fn: () => this.rotatePage(90),
                },
                {
                    name: "Rotate Counter Clockwise",
                    tooltip: "Rotate Page Counter Clockwise",
                    value: "rotate-ccw",
                    bl: false,
                    fn: () => this.rotatePage(-90),
                },
            ];
        },
        currentRightToolBar() {
            return {
                fields: "Fields",
                staticFields: "Static Fields",
                pages: "Organize Pages",
                customFields: "Custom Fields",
                edit: "Edit Field",
                options: "Template Options",
            };
        },
        rightToolbarOpen() {
            return Boolean(this.rightBarSelected);
        },
        selectedFields: {
            get() {
                return this.fields.filter((x) => {
                    if (x.group !== this.topFieldsGroup) return false;
                    if (x.hasOwnProperty("productType")) {
                        if (x.productType !== this.instance.productType)
                            return false;
                    }
                    if (x.hasOwnProperty("contractType")) {
						const allowedTypes = ["other", "reservation", "mutualRelease"];
						if (allowedTypes.includes(this.contract.type)) return true;
                        if (
                            !this.contract.type
                                .toLowerCase()
                                .includes(x.contractType.toLowerCase())
                        )
                            return false;
                    }
					if (this.topFieldsGroup == 'Product Custom Fields') return x.for == this.productType;
                    return true;
                });
            },
            set(val) {
                return;
            },
        },

        fieldsGrouped() {
            if (!this.fields) return {};
            let obj = {};
            this.fields.forEach((x) => {
                let { group } = x;
                if (!group) return;

                if (!obj[group])
                    obj[group] = { name: group, color: x.color, fields: [] };
                obj[group].fields.push(x);
            });
            return obj;
        },
        currentPageObj() {
            if (this.pages && this.pages.length)
                return this.pages[this.currentPage];
            else return { locked: false, pageNum: 0 };
        },
        instance() {
            return this.$store.state.instance;
        },
		deposits() {
			return this.$store.state.appData.deposits;
		},
		pagesOptions() {
            return this.pages.map((page, pageI) => {
                return {
                    label: `Page ${pageI + 1}`,
                    value: pageI,
                };
            });
        },

		parsedVariableInput() {
			const field = JSON.parse(JSON.stringify(this.newCustomField?.configure));
			const text = field?.defaultValue;

			if (!text) return [];

			const regex = /\{(.*?)\}/g;
			let match;
			const parts = [];
			let lastIndex = 0;
			let optionIndex = 0;
			this.newCustomField.configure.options = [];
			const uniqueVariables = new Map();

			while ((match = regex.exec(text)) !== null) {
				if (match.index > lastIndex) {
					parts.push({ type: 'text', value: text.slice(lastIndex, match.index) });
				}

				const variableName = match[1];

				if (!uniqueVariables.has(variableName)) {
					uniqueVariables.set(variableName, optionIndex);
					this.newCustomField.configure.options.push(
						{ value: field.options[optionIndex]?.value || "" }
					);
					optionIndex++;
				}

				parts.push({ type: 'variable', index: uniqueVariables.get(variableName) });

				lastIndex = regex.lastIndex;
			}

			if (lastIndex < text.length) {
				parts.push({ type: 'text', value: text.slice(lastIndex) });
			}

			return parts;
		},

		contractTemplate() {
			const template =  this.$store.state.appData.templates?.[this.$route.params.id]
			return template ? JSON.parse(JSON.stringify(template)) : {}
		}
    },
    methods: {
		goBack(){
			console.log('Going back...')
			Object.entries(this.eventListeners).forEach(([event, listener]) => {
				if (event == 'mouseover'){
					this.rightToolOpen.removeEventListener(event, listener);
				} else if (event == 'mouseout'){
					this.rightToolOpen.removeEventListener(event, listener);
				} else if (event == 'resize'){
					window.removeEventListener(event, listener);
				} else {
					document.removeEventListener(event, listener);
				}
			});
			this.$router.push(`/`)
		},
        copyPressed(ev) {
            if (this.selectedField) {
                ev.preventDefault();
                this.cloneObject = this.selectedField;
            } else {
                this.cloneObject = null;
            }
        },
        pastePressed(ev) {
            ev.preventDefault();
            if (this.cloneObject) {
                this.duplicateField();
            }
        },
        duplicateField() {
            if (this.cloneObject) {
                let field = JSON.parse(JSON.stringify(this.cloneObject));
                field.id = field.id.split("_")[0] + "_" + Date.now();
				field.repeatId = field.id
                field.x += 2;
                field.y -= 2;
                this.pages[this.currentPage].fields.push(field);
                this.settings.spots.push(field);
                this.$nextTick(() => {
                    editor.selectSpot(field.id);
                });
            }
        },
        duplicatePressed(ev) {
            ev.preventDefault();
            this.cloneObject = this.selectedField;
            this.duplicateField();
            this.cloneObject = null;
        },
        gotoPage(newIndex, oldIndex) {
            this.pageUpdating = true;
            this.selectedField = null;

            if (this.pages[oldIndex]) {
                this.updateBounds(oldIndex);
            }

            let page = this.pages[newIndex];
            if (!page) return console.error("cannot find page to updaet");

            this.createImageMap(page.id);

            this.editor.zoomReset();
        },
        saveFirst(type, i) {
            let self = this;
            let action =
                type === "insert"
                    ? "inserting page(s) or replacing a page"
                    : type === "duplicate"
                    ? "duplicating this page"
                    : "shuffling a page";

            this.$confirm({
                centered: true,
                title: `Before ${action}, we will have to save your progress. Continue?`,
                okText: "CONTINUE",
                cancelText: "CANCEL",
                onOk: async () => {
                    self.$store.commit("LOAD", true);
					try {
						await self.saveContract(true);
					} catch (err) {
						this.$toastError(err, 'Error occurred while saving contract. Please try again!')
					}
                    self.$store.commit("LOAD", false);

                    if (type === "insert") self.showInsertPages(i);
                    if (type === "duplicate") self.duplicatePage(i);
                    if (type === "shuffle") self.showShufflePage(i);
                },
            });
        },
        showInsertPages(i) {
            this.insertPagesModal = {
                index: i,
                show: true,
            };
        },
        showShufflePage(i) {
            this.shufflePageModal = {
                index: i,
                show: true,
            };
        },
        async updatePages(pages) {
            this.pages = pages;
            try {
                await this.saveContract(true);
            } catch (err) {
				this.$toastError(err, 'Error occurred while updating pages. Please try again!')
            }
            this.shufflePageModal.show = false;
        },
        duplicatePage(i) {
            this.$store.commit("LOAD", true);
            this.$api
                .post(
                    `/contract-templates/:instance/${this.contract.id}/copypage`,
                    { index: i }
                )
                .then(({ data }) => {
                    this.$message.success("Page Duplicated!");
                    this.$store.commit("SET_PROP", {
                        where: ["templates", data.id],
                        what: data,
                    });
                    this.setupView();
                })
                .catch((err) => {
                    this.$message.error("Error Duplicating Page");
                })
                .finally(() => {
                    this.$store.commit("LOAD", false);
                });
        },
        URLtoURI({ image, width, height, rotate }, degrees = 0) {
            return new Promise((resolve, reject) => {
                degrees = 0;
                if (rotate) degrees = rotate;

                let cv = document.createElement("canvas");
                let ctx = cv.getContext("2d");

                let img = new Image();
                img.crossOrigin = "anonymous";
                img.onload = () => {
                    var cw = img.width,
                        ch = img.height,
                        cx = 0,
                        cy = 0;

                    switch (degrees) {
                        case 90:
                            cw = img.height;
                            ch = img.width;
                            cy = img.height * -1;
                            break;
                        case 180:
                            cx = img.width * -1;
                            cy = img.height * -1;
                            break;
                        case 270:
                            cw = img.height;
                            ch = img.width;
                            cx = img.width * -1;
                            break;
                    }

                    cv.setAttribute("width", cw);
                    cv.setAttribute("height", ch);
                    ctx.rotate((degrees * Math.PI) / 180);
                    ctx.drawImage(img, cx, cy);

                    let image = cv.toDataURL();
                    return resolve({ image, width: cw, height: ch });
                };
                img.onerror = () => reject();
                img.src = image;
            });
        },
        async createImageMap(pageId) {
            let page = this.pages.find((x) => x.id === pageId);

            if (!page) return console.error("cannot find page");
            if (!page.fields) page.fields = [];

            let { image, width, height } = await this.URLtoURI(page);

            let settings = {
                image: {
                    url: image,
                },
                general: {
                    width,
                    height,
                    naturalWidth: width,
                    naturalHeight: height,
                },
                spots: page.fields || [],
            };

            $.image_map_pro_init_editor(settings, $.WCPEditorSettings, this);
            this.pageUpdating = false;
        },
        deleteCustomField(id) {
            let self = this;
            this.$confirm({
                title: "Are you sure you want to delete this Custom Field?",
                okText: "YES",
                okType: "danger",
                cancelText: "NO",
                centered: true,
                onOk() {
                    deleteProp(self.customFields, [id]);
                    self.pages.forEach((page, pageI) => {
                        page.fields = page.fields.filter(
                            (field) => field.field.value !== id
                        );
                    });
                    self.settings.spots = self.settings.spots.filter(
                        (x) => x.field.value !== id
                    );

                    self.editor.redraw();
                },
                class: "test",
            });
        },
        editCustomField(id) {
            this.editField = {
                show: true,
                id,
            };
        },
        addNewCustomOption() {
            this.newCustomField.configure.options.push({ value: "" });
        },
        updateDefaultCustomOption(e) {
            let value = e.target.value;
            this.newCustomField.configure.defaultValue = value;
        },
        makeCustomField() {
            this.dupFieldName = false;
            let self = this;
            this.$refs["newCustomField"].validate(async (valid) => {
                if (valid) {
                    let data = JSON.parse(JSON.stringify(self.newCustomField));
                    let value = self.editField.id || "";

                    let isDuplicateName = Object.values(self.customFields).some(
                        (x) => {
                            if (
                                self.editField.id &&
                                self.editField.id === x.value
                            )
                                return false;
                            return (
                                x.name.toLowerCase().trim() ===
                                data.name.toLowerCase().trim()
                            );
                        }
                    );

                    if (isDuplicateName) {
                        return (this.dupFieldName = true);
                    }

                    if (!self.editField.id) {
                        let id = Date.now() + "";
                        value = `other.customFields.${self.newCustomField.type}_${id}`;

                        data.value = value;
                        setProp(self.customFields, [value], data);
                        self.editField.show = false;
                    } else {
                        value = self.editField.id;

                        data.value = value;
                        setProp(self.customFields, [value], data);
                        self.pages.forEach((page, pageI) => {
                            page.fields.forEach((field, fieldI) => {
                                if (
                                    field.field.custom &&
                                    field.field.value === value
                                ) {
									self.pages[pageI].fields[fieldI].field = {
										...self.newCustomField,
										value,
									};

									self.pages[pageI].fields[fieldI].title = self.newCustomField.name

									if (self.pages[pageI].fields[fieldI].text && self.pages[pageI].fields[fieldI].text.text) {
										self.pages[pageI].fields[fieldI].text.text = self.newCustomField.name
									}

									if (
										self.pages[pageI].fields[fieldI].tooltip_content &&
										self.pages[pageI].fields[fieldI].tooltip_content.squares_settings &&
										self.pages[pageI].fields[fieldI].tooltip_content.squares_settings.containers &&
										self.pages[pageI].fields[fieldI].tooltip_content.squares_settings.containers[0] &&
										self.pages[pageI].fields[fieldI].tooltip_content.squares_settings.containers[0].settings &&
										self.pages[pageI].fields[fieldI].tooltip_content.squares_settings.containers[0].settings.elements[0] &&
										self.pages[pageI].fields[fieldI].tooltip_content.squares_settings.containers[0].settings.elements[0].options &&
										self.pages[pageI].fields[fieldI].tooltip_content.squares_settings.containers[0].settings.elements[0].options.heading) {
										self.pages[pageI].fields[fieldI].tooltip_content.squares_settings.containers[0].settings.elements[0].options.heading.text = self.newCustomField.name;
									}
								}
                            });
                        });

						let lookup = {
							multiple: "text",
							inclusion: "text",
							variable: "text",
							checkmark: "rect",
							date: "text",
						};

                        self.settings.spots.map((spot, spotI) => {
                            if (
                                spot.field.custom &&
                                spot.field.value === value
                            ) {
                                self.settings.spots[spotI].type =
                                    self.newCustomField.type === "text" || self.newCustomField.type === "inclusion" || self.newCustomField.type === 'variable'
                                        ? "text"
                                        : self.newCustomField.type === "image"
                                        ? "rect"
                                        : "spot";
								if (lookup[self.newCustomField.type]) self.settings.spots[spotI].type = lookup[self.newCustomField.type];
                                self.settings.spots[spotI].field = {
                                    ...self.newCustomField,
                                    value,
                                };
                            }
                        });

                        self.editor.redraw();
                        self.editField.show = false;
                    }
                } else {
                    return this.$message.error(
                        "Please review your fields and try again"
                    );
                }
            });
        },
        resetForm(formName) {
			if (this.$refs[formName]) {
				this.$refs[formName].resetFields();
				this.$refs[formName].clearValidate();
			}
		},
        async showPreview() {
            this.pageUpdating = true;

            try {
                await this.saveContract(true);
                let { data } = await this.$api.post(
                    `/contract-templates/:instance/${this.contract.id}/preview`
                );
                let { data: url } = await this.$api.get(
                    `/contract-templates/:instance/getfile?key=${data}`
                );

                this.$store.commit("SHOW_PREVIEW", url);
            } catch (err) {
				this.$toastError(err, 'Error occurred while previewing contract. Please try again!')
            }

            this.pageUpdating = false;
        },
        onToolVisible(val, tool) {
            if (val) {
                // trying to open a tooltip mode
                this.toolHoverMode = val;
                this.toolHovered = tool;
            } else {
                // closing a tooltip
                this.toolHoveredTO = setTimeout(() => {}, 1000);
            }
        },
        deselected() {
            if (this.selectedField && this.rightBarSelected === "edit")
                this.rightBarSelected = "fields";
            this.selectedField = null;
        },
        selectTool(toolName) {
            this.selectedTool = toolName;
            if (!this.settings || !this.settings.editor) return null;
            this.settings.editor.tool = toolName;
            this.editor.redraw();
        },

        createField(obj) {
			if(!obj){
				return;
			}
			console.log('CREATING FIELD...', obj)
            var s = $.extend(true, {}, $.imageMapProShapeDefaults);
            s.size = 1;
            s.id = obj.type + "_" + Date.now();
			s.repeatId =  s.id;
            let lookup = {
                multiple: "text",
                inclusion: "text",
				variable: "text",
                checkmark: "rect",
                date: "text",
            };

            s.title = obj.name;
            s.text.text = obj.name;

            if (obj.text) {
                s.title = obj.text;
                s.text.text = obj.text;
            }

            s.originalType = obj.type;
            s.type =
                obj.type === "text" || obj.type === "inclusion" || obj.type === "variable"
                    ? "text"
                    : obj.type === "image"
                    ? "rect"
                    : "spot";
            if (lookup[obj.type]) s.type = lookup[obj.type];

            if (obj.type === "image") {
                s.width = 6;
                s.height = 4;
				s.renderSize = 'medium'
				s.alignment = 'center'
            } else if (obj.type === "checkmark") {
                s.width = 4;
                s.height = 4;

                s.default_style.icon_type = "checkmark";
                s.default_style.icon_url = `https://bildhive.nyc3.digitaloceanspaces.com/assets/black-checkmark-png-4.png`;
            } else if (obj.textBox) {
                s.width = 6;
                s.height = 4;
            } else if (obj.type === 'date'){
				s.text.dateFormat = 'L/d/yyyy';
			}

            s.text.font_size = 11;
            s.text.purchasers = this.purchaserNo;
			s.text.purchaserX = this.purchaserXNo;
            s.text.signingParty = this.signingPartyNo;
            s.text.sellersAgents = this.sellersAgentsNo;
            if (obj.name.startsWith("AddOn Deposit")) {
                s.text.addOnDeposits = this.depositNo;
            } else {
                s.text.deposits = this.depositNo;
            }
            s.text.boundaries = Boolean(obj.boundaries);
            s.text.text_color = obj.color || "#000";
            s.default_style.icon_fill = obj.color || "#000";
            s.default_style.icon_type = obj.type;

            s.field = obj;
            s.text.text = obj.name;
            s.tooltip_content.squares_settings.containers[0].settings.elements[0].options.heading.text =
                s.title; // omg

			s.repeat = [this.currentPage]

            if (this.isTrue(this.settings.layers.enable_layers)) {
                s.layerID = this.settings.editor.currentLayer;
            } else {
                if (this.settings.layers.layers_list[0]) {
                    s.layerID = this.settings.layers.layers_list[0].id;
                } else {
                    s.layerID = 0;
                }
            }
            this.settings.spots.push(s);
            return s;
        },
        deleteField() {
            let self = this;
            this.$confirm({
                title: "Are you sure you want to delete this field?",
                okText: "YES",
                okType: "danger",
                cancelText: "NO",
                centered: true,
                onOk() {
                    if (
                        self.editor &&
                        self.editor.selectedSpot &&
                        self.editor.selectedSpot.id
                    ) {
                        self.deleteThisField(self.editor.selectedSpot.id);
                    }
                },
                class: "test",
            });
        },
        deleteThisField(id) {
            this.pages[this.currentPage].fields = this.pages[
                this.currentPage
            ].fields.filter((x) => x.id !== id);
            this.settings.spots = this.pages[this.currentPage].fields;
            this.deselect();
            this.editor.redraw();
        },
        deleteCurrentPage(pageNum = null) {
            if (typeof pageNum !== "number") pageId = this.currentPageObj.id;

            this.$confirm({
                title: "Are you sure you want to delete this page?",
                centered: true,
                okText: "YES",
                okType: "danger",
                cancelText: "NO",
                content: (h) => (
                    <div style="color:red;">
                        This page will be permanently removed from this
                        template!
                    </div>
                ),
                onOk: () => {
                    this.deletePage = this.pages[this.currentPage].id;
                    this.confirmDeletePage();
                },
                class: "test",
            });
        },
        screenToCanvasSpace(x, y, canvas) {
            return {
                x: Math.round((x - canvas.offset().left) * 1000) / 1000,
                y: Math.round((y - canvas.offset().top) * 1000) / 1000,
            };
        },
        applyOption(e, key) {
            this.selectedField[key] = e;

            let newFields = this.pages[this.currentPage].fields.map((x) => {
                if (x.id === this.selectedField.id) return this.selectedField;
                return x;
            });

            setProp(this.pages[this.currentPage], ["fields"], newFields);

            this.editor.redraw();
            this.editor.addAction();
        },
        changeStaticValue(e) {
            if (e == "https://") {
                e = "";
            }
            let newObj = { staticField: e };
            this.selectedField.field = {
                ...this.selectedField.field,
                ...newObj,
            };
            let newFields = this.pages[this.currentPage].fields.map((x) => {
                if (x.id === this.selectedField.id) return this.selectedField;
                return x;
            });

            setProp(this.pages[this.currentPage], ["fields"], newFields);

            this.editor.redraw();
            this.editor.addAction();
        },
        applyFont(e, key) {
            let newObj = { [key]: e };

            if (key === "boundaries" && e) {
                newObj.justChanged = true;
            }

            if (this.lastFormat.hasOwnProperty(key)) {
                this.lastFormat[key] = e;
            }

            this.selectedField.text = { ...this.selectedField.text, ...newObj };
            let obj = { [key]: e };
            let newFields = this.pages[this.currentPage].fields.map((x) => {
                if (x.id === this.selectedField.id) return this.selectedField;
                return x;
            });

            setProp(this.pages[this.currentPage], ["fields"], newFields);

            this.editor.redraw();
            this.editor.addAction();
        },

		// Repeat the selected field on same location for given pages
		applyFieldRepeat(pages) {
			const oldRepeat = this.selectedField.repeat
			this.$set(this.selectedField, 'repeat', [...pages])

			this.pages.forEach((page, pageI) => {
				const fieldIndex = this.pages[pageI].fields.findIndex(f => f.repeatId === this.selectedField.repeatId);
				if (pages.includes(pageI) && !oldRepeat.includes(pageI)) {
					this.pages[pageI].fields.push({ ...this.selectedField, id: this.selectedField.type + "_" + Date.now() })
					setProp(this.pages[pageI], ["fields"], this.pages[pageI].fields);
				} else if (oldRepeat.includes(pageI) && !pages.includes(pageI)) {
					this.pages[pageI].fields = this.pages[pageI].fields.filter((x) => x.repeatId !== this.selectedField.repeatId);
					setProp(this.pages[pageI], ["fields"], this.pages[pageI].fields);
				} else if (fieldIndex > -1) {
					this.pages[pageI].fields[fieldIndex].repeat = [...pages]
					setProp(this.pages[pageI], ["fields"], this.pages[pageI].fields);
				}
			})

			this.settings.spots = this.pages[this.currentPage].fields;

			this.editor.redraw();
			this.editor.addAction();
		},
        getCanvasPos(e) {
            let dom = document.querySelector("#wcp-editor-canvas");
            let rect = dom.getBoundingClientRect();
            let { bottom, height, left, right, top, width, x, y } = rect;

            this.canvasOrigPos = {
                bottom,
                height,
                left,
                right,
                top,
                width,
                x,
                y,
            };

            let wrapper = document.querySelector("#wcp-editor-center");
            let wrapperRect = wrapper.getBoundingClientRect();

            let {
                bottom: wBottom,
                left: wLeft,
                right: wRight,
                top: wTop,
                width: wWidth,
                height: wHeight,
            } = wrapperRect;


            this.canvasWrapper = {
                top: wTop,
                left: wLeft,
                right: wRight,
                bottom: wBottom,
                width: wWidth,
                height: wHeight,
            };
            this.canvasPos = { bottom, height, left, right, top, width, x, y };
        },
        isTrue(a) {
            if (parseInt(a, 10) == 1) return true;
            return false;
        },
        getLocalCoord(mouseX, mouseY) {
            if (this.isTrue(this.settings.editor.previewMode)) return;

            // Canvas space coords
            var point = this.screenToCanvasSpace(
                mouseX,
                mouseY,
                this.editor.canvas
            );

            let x = point.x;
            let y = point.y;

            this.dx = ((x - this.editor.ix) / this.editor.canvasWidth) * 100;
            this.dy = ((y - this.editor.iy) / this.editor.canvasHeight) * 100;

            this.dx = Math.round(this.dx * 1000) / 1000;
            this.dy = Math.round(this.dy * 1000) / 1000;

            return { x: this.dx, y: this.dy };
        },
        addLabel(e) {
			console.log('Adding label...', e)
            let { target } = e;
            let originalEvent = e;
            let { x: mouseX, y: mouseY } = originalEvent;
            let pos = this.getLocalCoord(mouseX, mouseY);

            let { top, bottom, left, right } = this.canvasPos;

            let hor = false;
            let ver = false;

            if (mouseX > left && mouseX < right) {
                hor = true;
            }

            if (mouseY > top && mouseY < bottom) {
                ver = true;
            }
            if (hor && ver && this.draggingField) {
                let point = this.screenToCanvasSpace(
                    mouseX,
                    mouseY,
                    this.editor.canvas
                );
                let localX = (point.x / this.canvasPos.width) * 100;
                let localY = (point.y / this.canvasPos.height) * 100;
                let editor = this.editor;

                // Draw spot

                let obj = this.fields.find(
                    (x) => x.value === this.draggingField && x.group === this.topFieldsGroup
                );

                if (this.customFields.hasOwnProperty(this.draggingField)) {
                    obj = this.customFields[this.draggingField];
                    obj.custom = true;
                }

                if (this.staticFields.hasOwnProperty(this.draggingField)) {
                    obj = this.staticFields[this.draggingField];
                    obj.static = true;
                    obj.staticValue = "";
                }

                if (!obj) {
                    // return this.$message.error('something happened')
                }

                var s = this.createField(obj);

				if (!s) {
					return;
				}

                s.x = Math.round(localX * 1000) / 1000;
                s.y = Math.round(localY * 1000) / 1000;

                s.text = {
                    ...s.text,
                    ...this.lastFormat,
                };

                editor.selectSpot(s.id);

                editor.redraw();
                editor.addAction();
                if (this.pages[this.currentPage] && !this.pages[this.currentPage].fields)
                    this.pages[this.currentPage].fields = [];
				if(this.pages[this.currentPage] && this.pages[this.currentPage].fields){
					this.pages[this.currentPage].fields.push(s);
				}
                this.updateBounds();
                this.selectTool("select");
            }

            this.draggingField = null;
        },
        deselect() {
            editor.deselectSpot();
        },
        tagDraggingPage(e) {
            let { newDraggableIndex, newIndex, oldDraggableIndex, oldIndex } =
                e;
        },
        async updatePagesOrder(e) {
            let { newDraggableIndex, newIndex, oldDraggableIndex, oldIndex } =
                e;
        },
		showEditField(field = null) {
			if (field) this.rightBarSelected = "edit";
			if (field && !field.repeat) {
				this.$set(field, 'repeat', [this.currentPage])
			}
			if (field && !field.repeatId) {
				this.$set(field, 'repeatId', field.id)
			}

			// set default render size for image fields
			if (field?.field?.type === 'image') {
				if (!field.renderSize){
					this.$set(field, 'renderSize', 'medium');
				}
				if (!field.alignment){
					this.$set(field, 'alignment', 'center');
				}
			}

			this.selectedField = field;
		},
        async confirmDeletePage() {
            let isCurrent = this.pages[this.currentPage]?.id === this.deletePage;

            this.pageUpdating = true;
            this.needsDelete = true;

            this.pages = this.pages.filter((page) => {
                return page.id !== this.deletePage;
            });

            if (isCurrent) {
                if (this.pages[this.currentPage]) {
                    this.currentPage--;
                }
            }

            this.$message.success("Page deleted successfully.");

            this.deletePage = "";

            this.pageUpdating = false;
            this.needsDelete = false;
        },

        async rotatePage(rotate) {
            this.pageUpdating = true;
            let currentRotate = this.pages[this.currentPage].rotate;
            if (!currentRotate) currentRotate = 0;

            currentRotate += rotate;

            this.pages[this.currentPage].rotate = currentRotate;
            this.createImageMap(this.pages[this.currentPage].id);

            this.pageUpdating = false;
        },

        getBounds(found) {
            let dom = document.querySelector(`[data-id=${found.id}]`);

            if (!dom) return console.error("cannot find dom");
            else {
                let rectInfo = JSON.parse(
                    JSON.stringify(dom.getBoundingClientRect())
                );

                let { x: left, y: top } = screenToCanvasSpace(
                    rectInfo.left,
                    rectInfo.top,
                    false,
                    true
                );
                let { x: right, y: bottom } = screenToCanvasSpace(
                    rectInfo.right,
                    rectInfo.bottom,
                    false,
                    true
                );

                found.bound = { left, top, right, bottom };
                return dom;
            }
        },
        updateBounds(page = null) {
            let dom = document.querySelector(`[data-id]`);
            if (!dom) return console.error("cannot find dom");

            let pageObj = this.currentPageObj || {};
            if (page !== null) pageObj = this.pages[page];

            if (!pageObj.fields) return (pageObj.fields = []);

            pageObj.fields.forEach((item) => {
                this.getBounds(item);
            });
        },
        cleanPages() {
            this.pages = this.pages.map((page) => {
                let ids = [];
                page.fields = page.fields.filter((field) => {
                    if (ids.includes(field.id)) return false;
                    ids.push(field.id);
                    return true;
                });
                return page;
            });
        },
        async saveContract(updatePDF = false) {
            return new Promise((resolve, reject) => {
                let signers = false;
                this.pages.forEach((x) => {
                    if (signers) return;
                    if (x.fields && x.fields.length) {
                        x.fields.forEach(({ field }) => {
                            if (field && (field.type === "sign" || field.type === "initial"))
                                return (signers = true);
                        });
                    }
                });
                this.cleanPages();
                this.updateBounds();
                this.$store.commit("LOAD", true);
                this.$api
                    .put(
                        `/contract-templates/:instance/${this.contract.id}/save`,
                        {
                            pages: this.pages,
                            customFields: this.customFields,
                            signers,
                            needsDelete: this.needsDelete || updatePDF,
                            files: this.contract.files,
							options: {
								...this.templateOptions
							}
                        }
                    )
                    .then(({ data }) => {
                        this.$store.commit("LOAD", false);
                        this.$store.commit("SET_PROP", {
                            where: ["templates", data.id],
                            what: data,
                        });
                        this.setupView();
                        this.needsDelete = false;
                        if (updatePDF) {
                            this.$message.success("Contract saved!");
                        }
                        resolve();
                    })
                    .catch((err) => {
                        this.$store.commit("LOAD", false);
                        this.pageUpdating = false;
                        reject(err);
                    });
            });
        },

        async save() {
            this.pageUpdating = true;
			try {
				await this.saveContract(true);
			} catch (err) {
				this.$toastError(err, 'Error occurred while saving contract. Please try again!')
			}
            this.pageUpdating = false;
        },
        updateViewport() {
            if (document.querySelector("#imp-editor-shapes-container")) {
                this.canvasWidth = document.querySelector(
                    "#imp-editor-shapes-container"
                ).clientWidth;
                this.canvasHeight = document.querySelector(
                    "#imp-editor-shapes-container"
                ).clientHeight;
            }
        },
        setupView(data) {
            if (data && data.id && data.files && data.files.length) {
                this.$store.commit("SET_PROP", {
                    where: ["templates", data.id],
                    what: JSON.parse(JSON.stringify(data)),
                });
            } else {
                data = this.contractTemplate;
            }
            let customFields = data && data.customFields ? data.customFields : {};
            this.customFields = JSON.parse(JSON.stringify(customFields));
            this.contract.files = data && data.files ? data.files : this.contract.files;

			if (data && data.pages) {
				this.contract.pages = typeof data.pages === "string" ? JSON.parse(data.pages) : data.pages;
			}

            this.pages = this.contract.pages;
			if (!this.pages?.[this.currentPage]?.id) {
				this.currentPage = 0;
			}
            this.createImageMap(this.pages[this.currentPage].id);
        },

		async getTransactionDeposits() {
			try {
				const { data } = await this.$api.get(`/settings/:instance/transactions`)
				if (data && data.options && data.options.deposits && data.options.deposits.length) {
					this.$store.commit('SET_DEPOSITS', data.options.deposits)
				}
			} catch (error) {
				console.error('Error while fetching transaction deposits', error)
			}
		},

		onOptionDelete(newCustomField, index) {
			// Updating default value based on delete index
			if (newCustomField.configure.options.length === index + 1) {
				newCustomField.configure.defaultValue = (index - 1).toString();
			} else {
				newCustomField.configure.defaultValue = (newCustomField.configure.defaultValue - 1).toString();
			}
			newCustomField.configure.options.splice(index, 1)
		},

		async fetchModelDetails() {
			try {
				let { data } = await this.$api.get(`unit-groups/:instance/properties`);
				this.modelDetails = {
					frontages: data.sizes.filter(s => s),
					modelTypes: data.types.filter(t => t),
					stories: data.stories.filter(s => s),
				};
			} catch (err) {
				this.$toastError(err, `An error occurred while fetching model details. Please try again.`)
			}
		},

		onTemplateCriteriaChange(option, value) {
			this.templateOptions.filterCriteria[option] = this.templateOptions.filterCriteria[option] !== value ? value : null;
		},
    },
    async created() {
		this.getTransactionDeposits();
		this.fetchModelDetails();

		this.contract = this.contractTemplate;
		this.$store.commit("LOAD", true);
        let type = this.contract.type?.toLowerCase() || '';

        if (type.includes("purchase") || type.includes("mutualrelease")) type = "purchase";
		else if (type.includes("lease")) {
            type = "lease";
            this.topFieldsGroup = "Tenant(s)";
        }
		var allFields = [];
		let tempFields = [];
		if (this.instance.productType == 'lowrise'){
			this.productType = 'lot'
			var { data: lotCustomFields } = await this.$api.get(`/custom-fields/:instance?for=lot`);
			await this.$api.get(`/settings/:instance/models`).then(({data: modelCustomFields}) => {
				modelCustomFields = modelCustomFields?.options?.customFields?.map(x => {
					x.for = 'models'
					return x;
				}) || [];
				allFields = [...lotCustomFields,...modelCustomFields];
			}).catch((err) => {
				console.log('err', err);
				allFields = [...lotCustomFields];
			});
		} else if (this.instance.productType == 'highrise'){
			this.productType = 'units'
			await this.$api.get(`/settings/:instance/condounits`).then(({data: unitCustomFields}) => {
				unitCustomFields = unitCustomFields?.options?.customFields.map(x => {
					x.for = 'units'
					return x;
				}) || [];
				allFields = [...unitCustomFields];
			}).catch((err) => {
				console.log('err', err);
				allFields = [];
			});
		}
		for (let i = 0; i < allFields.length; i++){
			let newField = {};
			if (allFields[i].type == 'multiplechoice'){
				let fn = `transaction.product.lot?.other?.customFields?.${allFields[i].id} ? transaction.product.lot.other.customFields.${allFields[i].id}.join(', ') : ''`;
				let liquid = `{% assign custom_fields = product.lot.other.customFields.${allFields[i].id} %} {% if custom_fields %} {{ custom_fields | join: ", " }} {% endif %}`;
				if (allFields[i].for == 'models' || allFields[i].for == 'units'){
					fn = `transaction.product.unit?.package?.other?.cf?.${allFields[i].id} ? transaction.product.unit?.package?.other?.cf?.${allFields[i].id}.join(', ') : ''`;
					liquid = `{% assign custom_fields = product.unit.package.other.cf.${allFields[i].id} %} {% if custom_fields %} {{ custom_fields | join: ", " }} {% endif %}`;
				}
				newField = {
					"name": allFields[i].name,
					"value": `product.lot.other.customFields.${allFields[i].id}`,
					"eval": true,
					"fn": fn,
					"liquid": liquid,
					"productType": this.instance.productType,
					"color": "#000000",
					"type": "text",
					"text": allFields[i].name,
					"for": allFields[i].for,
					"group": "Product Custom Fields"
				}
			} else if (allFields[i].type == 'date'){
				let value = `product.lot.other.customFields.${allFields[i].id}`;
				if (allFields[i].for == 'models' || allFields[i].for == 'units'){
					value = `product.unit.package.other.cf.${allFields[i].id}`;
				}
				newField = {
					"name": allFields[i].name,
					"value": value,
					"productType": this.instance.productType,
					"color": "#000000",
					"type": "date",
					"text": allFields[i].name,
					"for": allFields[i].for,
					"group": "Product Custom Fields"
				}
			} else {
				let value = `product.lot.other.customFields.${allFields[i].id}`;
				if (allFields[i].for == 'models' || allFields[i].for == 'units'){
					value = `product.unit.package.other.cf.${allFields[i].id}`;
				}
				newField = {
					"name": allFields[i].name,
					"value": value,
					"productType": this.instance.productType,
					"color": "#000000",
					"type": "text",
					"text": allFields[i].name,
					"for": allFields[i].for,
					"group": "Product Custom Fields"
				}
			}
			tempFields.push(newField);
		}

		try {
			let { data: fields } = await this.$api.get(
				`/contract-templates/:instance/getfields?type=${type}`
			);
			this.fields = [...fields,...tempFields];
		} catch (err) {
			this.$toastError(err, `An error occurred while fetching contract template fields. Please try again.`)
		}

		// Initialize template options based on product type
        const defaultOptions = { depositStructure: null, doNotSendCopyTo: { purchaser: false, lawyer: false, agent: false, seller: false } };
        if (this.instance.productType === 'lowrise') {
            Object.assign(defaultOptions, { filterCriteria: { frontage: null, modelType: null, stories: null } });
        }
		this.templateOptions = { ...defaultOptions, ...(this.contract.options || {}) };

		// remove depositStructure option if it's not found from deposits list
		if(this.deposits && this.deposits.length && this.templateOptions.depositStructure){
			const found = this.deposits.find(d => d.id === this.templateOptions.depositStructure)
			if(!found){
				this.templateOptions.depositStructure = null;
			}
		}

        this.$store.commit("PAGE_BUTTONS", [
            {
                label: "PREVIEW",
                ghost: true,
                callback: () => this.showPreview(),
            },
            {
                label: "SAVE CONTRACT",
                callback: () => this.save(),
            },
        ]);
        window.contractVue = this;

        this.setupView();
        this.updateViewport();
    },
    mounted() {
		console.log('MOUNTED RAN')
		this.rightToolOpen = document.querySelector(".right-tool-open");
		this.eventListeners = {
			mousedown: (e) => {
				let { target } = e;
				this.draggingField = null;
				if (
					target.dataset &&
					target.dataset.fieldValue &&
					target.className &&
					target.className.includes("each-field")
				) {
					this.draggingField = target.dataset.fieldValue;
					this.getCanvasPos(e);
				}
			},
			mouseover: (e) => {
				document.onselectstart = function () {
                    return false;
                };
			},
			mouseout: (e) => {
				document.onselectstart = function () {
                    return true;
                };
			},
			mousemove: (e) => {
				let { target } = e;

				if (
					this.draggingField &&
					document.querySelector("body:not(.field-dragging)")
				) {
					document.body.classList.add("field-dragging");
				}
			},
			mouseup: (e) => {
				let { target } = e;
				if (this.draggingField) {
					e.preventDefault();
					document.body.classList.remove("field-dragging");
					this.addLabel(e);
					this.draggingField = null;
				}
				document.onselectstart = function () {
					return true;
				};
			},
			resize: (e) => {
				this.updateViewport();
			},
			keydown: (ev) => {
				if (ev.target.tagName !== "BODY")
                    return;
                ev = ev || window.event;
                var ctrl = ev.ctrlKey || ev.metaKey;
                if (ev.key == "v" && ctrl) {
                    this.pastePressed(ev);
                } else if (ev.key == "c" && ctrl) {
                    this.copyPressed(ev);
                } else if (ev.key == "d" && ctrl) {
                    this.duplicatePressed(ev);
                } else if (ev.key == "h" && ctrl) {
                    ev.preventDefault();
                    let dom = document.querySelector(
                        ".imp-editor-shape.selected"
                    );
                    if (dom) {
                        let bound = dom.getBoundingClientRect();
                        let { x, y, width, height, left, top, right, bottom } =
                            bound;
                    }
                }
			}
		}

		Object.entries(this.eventListeners).forEach(([event, listener]) => {
			if (event == 'mouseover'){
				if(this.rightToolOpen){
					this.rightToolOpen.addEventListener(event, listener);
				}
			} else if (event == 'mouseout'){
				if(this.rightToolOpen){
					this.rightToolOpen.addEventListener(event, listener);
				}
			} else if (event == 'resize'){
				window.addEventListener(event, listener);
			} else {
				document.addEventListener(event, listener);
			}
		});

        this.updateViewport();
        setTimeout(() => {
            this.updateViewport();
        }, 200);
    },
    beforeDestroy() {
		if(this.settings){
			this.settings.spots = [];
		}
        window.removeEventListener("beforeunload", func);
    },
};
</script>

<style scoped lang="scss">
.backButton {
    color: black;
    transition: color 0.2s ease-in-out;
}
.backButton:hover {
    color: var(--orange);
    transition: color 0.2s ease-in-out;
}
.pageDelete {
    color: black;
    transition: color 0.2s ease-in-out;
}
.pageDelete:hover {
    color: var(--danger);
    transition: color 0.2s ease-in-out;
}
</style>
<style lang="scss">
#wcp-editor-tooltip {
    font-size: 10px;
    padding: 0 5px;
}
.imp-editor-text {
    // &[data-static] {
        // border: calc(var(--font-size) * 0.1) solid black;
    // }
    &:not([data-static]) {
        background: var(--selectColor) !important;
        color: white !important;
    }
    &.selected {
        outline: 0.2ch dashed black;
        box-shadow: 0 0 0.8ch rgba(0, 0, 0, 0.8);
    }
    .purchaserNo {
        padding: calc(var(--font-size) * 0.3);
        font-size: calc(var(--font-size) * 0.6);
        position: absolute;
        border-radius: calc(var(--font-size) * 0.1);
        background: #aaa;
        color: white;
        right: 0;
        top: 0;
        transform: translate(100%, -40%);
    }
}
.imp-editor-rect {
}

.imp-editor-rect[data-id^="text"] {
    background: var(--selectColor) !important;
    color: white !important;
}

.imp-editor-rect[data-id^="date"] {
    background: var(--selectColor) !important;
    color: white !important;
}

.imp-editor-rect[data-id^="multiple"] {
    background: var(--selectColor) !important;
    color: white !important;
}

.imp-editor-rect[data-id^="inclusion"] {
    background: var(--selectColor) !important;
    color: white !important;
}

.imp-editor-rect[data-id^="variable"] {
    background: var(--selectColor) !important;
    color: white !important;
}

// Remove border from static field checkmark
.imp-editor-rect[data-id^="checkmark"] {
    border: 0 !important;
}

body {
    --fieldType1: var(--primary);
}
.rightBarToggle {
    width: 46px;
    background: white;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    > .right-toolbar-item {
        width: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.active {
            background: #f8f8f8;
        }
    }
}
.page-popover {
    z-index: 1000000;
    .ant-popover-inner-content {
        padding: 0;
    }
    .more-item {
        padding: 4px 8px;
        cursor: pointer;
        &:hover {
            background: var(--off-white-light);
        }
    }
}
.right-tool-bar {
    transition: width 0.3s ease-out;
}

.pdf-toolbar {
    > div {
        padding: 10px;
        color: var(--new-med-gray);
        transition: background 0.3s ease-out;
        &.active {
            color: var(--primary);
        }
        &:not(:last-child):hover {
            // background:var(--light-purple);
            background: rgba(100, 100, 100, 0.05);
        }
        &.tool-bL {
            border-left: 1px solid var(--light-purple);
        }
        > svg {
            width: 20px;
            height: 20px;
        }
    }
}

#wcp-editor-toolbar-wrap {
    display: none;
}
#wcp-editor {
    background: transparent;
}
.custom-field__edit {
    pointer-events: all !important;
}
.each-field {
    border: 1px dashed transparent;
    * {
        pointer-events: none;
    }

    &.field-drag {
        border-color: var(--primary);
    }
    svg {
        max-width: 18px;
        max-height: 14px;
    }
}
.page-modal-container {
    position: fixed;
    left: 0;
    width: 100vw;
    top: 0;
    height: 100vh;
    z-index: 10000;
    pointer-events: none;
    .page-modal-content-wrapper,
    .page-modal-container-mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
    }

    .page-modal-container-mask {
        // background:rgba(0,0,0,0.8);
        background: rgba(68, 66, 64, 0.84);
        pointer-events: auto;
    }

    .page-modal-content-wrapper {
        > .container {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            right: 0;
            top: 0;
            bottom: 0;
            pointer-events: auto;
            overflow: hidden;
        }
        .ant-card-actions {
            background: var(--light-gray);
        }
    }
}
.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s;
    * {
        transition: all 0.5s;
    }
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    * {
        opacity: 0;
    }
}
.field-options-footer {
    // min-height:500px;
    // background:#f7f5f9;
}
.muted-colors {
    color: #bfbfbf;
}
.right-tool-bar {
    width: 0px;
    overflow: hidden;
}
.right-tool-open {
    .right-tool-bar {
        width: 300px;
        overflow: hidden;
        user-select: none;
    }
}
.pdf-canvas-wrapper {
    flex: 1;
    position: relative;
}
#fieldOptions {
    transition: width 0.3s ease-out;
    border-left: 1px solid var(--light-purple);
    // width:25%;
}
.page-block {
    position: relative;
    margin-bottom: 40px;
    &:after {
        display: block;
        text-align: center;
        content: attr(data-page-index);
        margin-top: 10px;
    }

    > ul {
        list-style: none;
        margin: 10px 0 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        > li {
            margin-bottom: 10px;
        }
    }
    flex: 0 0 auto;
    > .page-img-holder {
        border: 1px solid var(--light-purple);
    }
    img {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: contain;
    }

    &.page-selected {
        &:after {
            color: var(--primary);
        }
        > .page-img-holder {
            border-color: var(--primary);
        }
    }
    &:hover {
        // 1px 3px 10px rgba(100,100,100,0.1)
    }
}
.page-block-container {
    display: flex;
    flex-wrap: wrap;
    padding: 100px 50px;
}

[data-field-value] {
    padding: 1px 2px;
}
[data-field-value="contact1"] {
    background: rgba(157, 198, 202, 0.55);
    color: black !important;
}
[data-field-value="contact2"] {
    background: rgba(239, 25, 25, 0.55);
    color: black !important;
}

#wcp-editor-center {
    margin-left: 0;
}
.imp-editor-shape.selected .imp-selection {
    background: rgba(200, 200, 200, 0.1);
    border: 1px solid var(--selectColor, turquoise);
    border-radius: 0px !important;
}
#imp-editor-shapes-container::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border: 3px dashed transparent;
    transition: border 0.3s ease-in-out;
    pointer-events: none;
	border-color: #aaa;
}
body.field-dragging {
    cursor: copy;
    #imp-editor-shapes-container::after {
        border-color: red;
    }
}

.each-field {
    ::selection {
        background: none;
    }
    &:hover {
        background: #f3f3f3;
    }
}
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}
.scroll-parent {
    position: relative;
    overflow: hidden;
    > .scroll-child {
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        right: -17px;
        overflow-y: scroll;
    }
}

.imp-editor-shape.selected {
    svg {
        box-shadow: 0 0 5px;
    }
    .select-rect {
        stroke: inherit;
    }
    text {
        fill: inherit;
    }
}

.custom-field-item {
    .handle {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    &:not(:hover) {
        .custom-field__edit {
            opacity: 0;
        }
    }
}

span.purchaserNo {
    color: #aaa;
}

.pdf-toolbar *,
.no-select,
.no-select-parent > * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}
.dragging-field-preview {
    opacity: 0;
    visibility: 0;
    position: absolute;
    pointer-events: none;
    width: 300px;
    height: 200px;
}
</style>
